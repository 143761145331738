import React from 'react'
import Extension from './Extension'

type Props = {}

export default function ExtensionPage({ }: Props) {
    return (
        <div>
            <Extension/>
        </div>
    )
}