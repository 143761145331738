import { Order, PythonGenerator } from 'blockly/python';

import Blockly from "./micropython-blocks";
import { controls_flow_statements, controls_for, controls_forEach, controls_if, controls_repeat_ext, controls_whileUntil, logic_boolean, logic_compare, logic_negate, logic_null, logic_operation, logic_ternary, math_arithmetic, math_atan2, math_change, math_constant, math_constrain, math_modulo, math_number, math_number_property, math_on_list, math_random_float, math_random_int, math_round, math_single, math_trig, procedures_callnoreturn, procedures_callreturn, procedures_defreturn, procedures_ifreturn, text, text_append, text_changeCase, text_charAt, text_count, text_getSubstring, text_indexOf, text_isEmpty, text_join, text_length, text_print, text_prompt_ext, text_replace, text_reverse, text_trim, variables_get, variables_set } from './micropython-traditional-blocks-resolver';
import { Workspace } from 'blockly';
import MicroPythonToolBox from './micropython-toolbox';

const pythonGenerator = new PythonGenerator()

pythonGenerator.forBlock['controls_if'] = controls_if
pythonGenerator.forBlock['logic_compare'] = logic_compare
pythonGenerator.forBlock['logic_operation'] = logic_operation
pythonGenerator.forBlock['logic_negate'] = logic_negate
pythonGenerator.forBlock['logic_boolean'] = logic_boolean
pythonGenerator.forBlock['logic_null'] = logic_null
pythonGenerator.forBlock['logic_ternary'] = logic_ternary
pythonGenerator.forBlock['controls_repeat_ext'] = controls_repeat_ext
pythonGenerator.forBlock['controls_whileUntil'] = controls_whileUntil
pythonGenerator.forBlock['controls_for'] = controls_for
pythonGenerator.forBlock['controls_forEach'] = controls_forEach
pythonGenerator.forBlock['controls_flow_statements'] = controls_flow_statements
pythonGenerator.forBlock['math_number'] = math_number
pythonGenerator.forBlock['math_arithmetic'] = math_arithmetic
pythonGenerator.forBlock['math_single'] = math_single
pythonGenerator.forBlock['math_constant'] = math_constant
pythonGenerator.forBlock['math_number_property'] = math_number_property
pythonGenerator.forBlock['math_change'] = math_change
pythonGenerator.forBlock['math_on_list'] = math_on_list
pythonGenerator.forBlock['math_modulo'] = math_modulo
pythonGenerator.forBlock['math_constrain'] = math_constrain
pythonGenerator.forBlock['math_random_int'] = math_random_int
pythonGenerator.forBlock['math_random_float'] = math_random_float
pythonGenerator.forBlock['math_atan2'] = math_atan2
pythonGenerator.forBlock['procedures_defreturn'] = procedures_defreturn
pythonGenerator.forBlock['procedures_callreturn'] = procedures_callreturn
pythonGenerator.forBlock['procedures_callnoreturn'] = procedures_callnoreturn
pythonGenerator.forBlock['procedures_ifreturn'] = procedures_ifreturn
pythonGenerator.forBlock['text'] = text
pythonGenerator.forBlock['text_join'] = text_join
pythonGenerator.forBlock['text_append'] = text_append
pythonGenerator.forBlock['text_length'] = text_length
pythonGenerator.forBlock['text_isEmpty'] = text_isEmpty
pythonGenerator.forBlock['text_indexOf'] = text_indexOf
pythonGenerator.forBlock['text_charAt'] = text_charAt
pythonGenerator.forBlock['text_getSubstring'] = text_getSubstring
pythonGenerator.forBlock['text_changeCase'] = text_changeCase
pythonGenerator.forBlock['text_trim'] = text_trim
pythonGenerator.forBlock['text_print'] = text_print
pythonGenerator.forBlock['text_prompt_ext'] = text_prompt_ext
pythonGenerator.forBlock['text_count'] = text_count
pythonGenerator.forBlock['text_replace'] = text_replace
pythonGenerator.forBlock['text_reverse'] = text_reverse
pythonGenerator.forBlock['variables_get'] = variables_get
pythonGenerator.forBlock['variables_set'] = variables_set




export class MicroPythonGenerator {
    import_statements: string;
    initialization_statements: string;
    pythonGenerator: PythonGenerator;
    new_blocks: string[]
    final_new_blocks: string[]
    constructor(pythonGenerator: PythonGenerator) {
        this.import_statements = "";
        this.initialization_statements = "";
        this.pythonGenerator = pythonGenerator;
        this.new_blocks = []
        this.final_new_blocks = []
    }
    add_import_statement(statement: string) {
        // console.log(this.import_statements);
        let import_statement = `${statement}\n`;
        if (!this.import_statements.includes(import_statement)) {
            this.import_statements += import_statement;
        }
    }
    add_new_blocks(name: string) {
        if (!this.new_blocks.includes(name)) {
            this.new_blocks.push(name)
        }
    }
    add_initialization_statement(statement: string) {
        let initialize_statement = `${statement}\n`;
        if (!this.initialization_statements.includes(initialize_statement)) {
            this.initialization_statements += initialize_statement;
        }
    }
    getNewBlocks() {
        return this.final_new_blocks
    }
    getPythonCode(workspace: Workspace) {
        const finalCode = this.pythonGenerator.workspaceToCode(workspace);
        const pythonCode = this.import_statements + this.initialization_statements + finalCode
        // console.log(this.import_statements);
        // console.log("generated code", pythonCode);
        let temp: string[] = []
        // console.log(this.new_blocks);

        this.new_blocks.forEach(element => {
            // console.log(`request.find('/'+${element}')==6`);

            if (pythonCode.includes(`request.find('/'+${element})==6`))
                temp.push(element);
        });
        // console.log(temp);

        this.final_new_blocks = [...temp];
        this.import_statements = "";
        this.initialization_statements = "";
        return pythonCode
    }
}


export const microPythonResolver = new MicroPythonGenerator(pythonGenerator)

pythonGenerator.forBlock['initialize_wifi'] = function (block, pythonGenerator) {

    microPythonResolver.add_import_statement(`import network`);
    microPythonResolver.add_import_statement(`import socket`);
    microPythonResolver.add_import_statement(`import json`);
    microPythonResolver.add_import_statement(`import time`);
    microPythonResolver.add_import_statement(`import machine`);
    let ssid = pythonGenerator.valueToCode(block, 'ssid', Order.ATOMIC);
    let password = pythonGenerator.valueToCode(block, 'password', Order.ATOMIC);
    microPythonResolver.add_initialization_statement(`IndicatorPin = machine.Pin(2, machine.Pin.OUT)
ssid = ${ssid}
password = ${password}
wlan = network.WLAN(network.STA_IF)
ipaddress=""
`);
    microPythonResolver.add_initialization_statement(`def connectToWiFi():
    global ipaddress
    wlan.active(True)
    print('Attempting to connect to the network...')
    wlan.connect(ssid, password)        
    max_wait = 10
    while max_wait > 0 and not wlan.isconnected():
        max_wait -= 1
        print('waiting for connection...')
        time.sleep(1)
    if not wlan.isconnected():
        print('Network Connection has failed')
    else:
        print('Connected to the network successfully.')
        IndicatorPin.value(1)
        time.sleep(0.2)
        IndicatorPin.value(0)
        time.sleep(0.2)
        IndicatorPin.value(1)
        time.sleep(0.2)
        IndicatorPin.value(0)
        status = wlan.ifconfig()
        ipaddress=status[0]
        with open("config.json", "w") as f:
            json.dump({
            "wifiName":ssid,
            "wifiPassword":password,
            "IPAddress":ipaddress
            }, f)
        print( 'Enter this address in browser = ' + status[0] )
`);

    return `connectToWiFi()\n`
}
pythonGenerator.forBlock['is_wifi_connected'] = function (block, pythonGenerator) {
    const code = 'wlan.isconnected()'
    return [code, 0]
}
pythonGenerator.forBlock['start_server'] = function (block, pythonGenerator) {
    const code = `addr = socket.getaddrinfo('0.0.0.0', 80)[0][-1]
s = socket.socket()
s.setblocking(0)
s.bind(addr)
s.listen(1)
header = """HTTP/1.0 200 OK
Content-Type: text/html; charset=utf-8
Access-Control-Allow-Origin: *;

"""
`
    return code
}
pythonGenerator.forBlock['get_ip_address'] = function (block, pythonGenerator) {
    const code = 'ipaddress'
    return code
}
pythonGenerator.forBlock['handle_request'] = function (block, pythonGenerator) {
    let ProjectName = pythonGenerator.valueToCode(block, 'ProjectName', Order.ATOMIC);

    const statements = pythonGenerator.statementToCode(block, "DO")
    const code = `
try:
  cl, addr = s.accept()
  print('client connected from', addr)
  request = cl.recv(1024)
  print(request)
  request = str(request)
  response = ${ProjectName}\n` + statements + `

  cl.send(header)
  cl.sendall(response)

  cl.close()
except Exception as e:
  print("Error: " + str(e))
  pass
`
    return code
}
pythonGenerator.forBlock['is_request_equals_to'] = function (block, pythonGenerator) {
    let request_value = pythonGenerator.valueToCode(block, 'request_value', Order.ATOMIC);
    microPythonResolver.add_new_blocks(request_value)
    const code = `request.find('/'+${request_value})==6`
    return [code, 0]
}
pythonGenerator.forBlock['call_function'] = function (block, pythonGenerator) {
    let call = pythonGenerator.valueToCode(block, 'call', Order.FUNCTION_CALL);
    const code = `${call}` + "\n"
    return code
}

pythonGenerator.forBlock['set_led'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    var value_value = pythonGenerator.valueToCode(block, 'value', Order.ATOMIC);
    var code = `led${value_pin}.value(${value_value})\n`;
    //import statements
    microPythonResolver.add_initialization_statement(`led${value_pin} = Pin(${value_pin}, Pin.OUT)`);
    microPythonResolver.add_import_statement("from machine import Pin\n");
    return code;
};


pythonGenerator.forBlock['delay'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_delay = pythonGenerator.valueToCode(block, 'delay', Order.ATOMIC);
    var code = `time.sleep(${value_delay})\n`;
    //import statements
    microPythonResolver.add_import_statement("import time");
    return code;
};

pythonGenerator.forBlock['print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value = pythonGenerator.valueToCode(block, 'print', Order.ATOMIC);
    var code = `print(${value})\n`;
    //import statements
    return code;
};


pythonGenerator.forBlock['abs'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    var code = `abs(${value_name})\n`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['map'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_value = pythonGenerator.valueToCode(block, 'value', Order.ATOMIC);
    var value_fl = pythonGenerator.valueToCode(block, 'fl', Order.ATOMIC);
    var value_fh = pythonGenerator.valueToCode(block, 'fh', Order.ATOMIC);
    var value_tl = pythonGenerator.valueToCode(block, 'tl', Order.ATOMIC);
    var value_th = pythonGenerator.valueToCode(block, 'th', Order.ATOMIC);

    //import statements
    microPythonResolver.add_initialization_statement(`def  map_value(x, in_min, in_max, out_min, out_max):
    return int((x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min)`);

    var code = `map_value(${value_value},${value_fl},${value_fh},${value_tl},${value_th})`;

    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['min'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_l = pythonGenerator.valueToCode(block, 'l', Order.ATOMIC);
    var value_h = pythonGenerator.valueToCode(block, 'h', Order.ATOMIC);
    var code = `min(${value_l},${value_h})`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['max'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_l = pythonGenerator.valueToCode(block, 'l', Order.ATOMIC);
    var value_h = pythonGenerator.valueToCode(block, 'h', Order.ATOMIC);
    var code = `max(${value_l},${value_h})`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['loop_esp'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var statements_name = pythonGenerator.statementToCode(block, 'NAME');
    //import statements
    microPythonResolver.add_import_statement("import machine\nfrom machine import Pin")
    var code = '\nwhile True:\n' + statements_name;
    return code;
};



pythonGenerator.forBlock['text-block'] = function (block, pythonGenerator) {
    return [`'${block.getFieldValue("text")}'`, Order.ATOMIC];

}


pythonGenerator.forBlock['serial_begin'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var dropdown_name = block.getFieldValue('NAME');
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import UART');

    microPythonResolver.add_initialization_statement(`uart = UART(2, ${dropdown_name})
uart.init(rx=16,tx=17)`);
    var code = '';
    return code;
};



pythonGenerator.forBlock['serial_read'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'uart.read()';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['serial_print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    var dropdown_name = block.getFieldValue('NAME');
    var code = `uart.write(${value_name})\n`;
    return code;
};

pythonGenerator.forBlock['serial_available'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'uart.any()';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['read_string'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'uart.readline()';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['parse_int'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'int(uart.read())';
    return [code, Order.ATOMIC];
};



pythonGenerator.forBlock['set_buzzer'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    var value_value = pythonGenerator.valueToCode(block, 'value', Order.ATOMIC);

    //import statements
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`buzzer${value_pin} = Pin(${value_pin}, Pin.OUT)`);

    var code = `buzzer${value_pin}.value(${value_value})\n`;
    return code;
};


pythonGenerator.forBlock['set_motor'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin1 = pythonGenerator.valueToCode(block, 'pin1', Order.ATOMIC);
    var value_pin2 = pythonGenerator.valueToCode(block, 'pin2', Order.ATOMIC);
    var value_value1 = pythonGenerator.valueToCode(block, 'value1', Order.ATOMIC);
    var value_value2 = pythonGenerator.valueToCode(block, 'value2', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`P${value_pin1}M = Pin(${value_pin1},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`P${value_pin2}M = Pin(${value_pin2},Pin.OUT)`);

    var code = `P${value_pin1}M.value(${value_value1})
P${value_pin2}M.value(${value_value2})\n`;
    return code;
};


pythonGenerator.forBlock['set_bo_motor'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin1 = pythonGenerator.valueToCode(block, 'pin1', Order.ATOMIC);
    var value_pin2 = pythonGenerator.valueToCode(block, 'pin2', Order.ATOMIC);
    var value_value1 = pythonGenerator.valueToCode(block, 'value1', Order.ATOMIC);
    var value_value2 = pythonGenerator.valueToCode(block, 'value2', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`P${value_pin1}boM = Pin(${value_pin1},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`P${value_pin2}boM = Pin(${value_pin2},Pin.OUT)`);

    var code = `P${value_pin1}boM.value(${value_value1})
P${value_pin2}boM.value(${value_value2})\n`;
    return code;
};


pythonGenerator.forBlock['traffic_light_define_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_red = pythonGenerator.valueToCode(block, 'red', Order.ATOMIC);
    var value_yellow = pythonGenerator.valueToCode(block, 'yellow', Order.ATOMIC);
    var value_green = pythonGenerator.valueToCode(block, 'green', Order.ATOMIC);
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`red = Pin(${value_red},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`yellow = Pin(${value_yellow},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`green = Pin(${value_green},Pin.OUT)`);

    var code = ``;
    return code;
};

pythonGenerator.forBlock['pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var dropdown_pins = block.getFieldValue('pins');
    var code = `${dropdown_pins}`;
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['analog_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var dropdown_pins = block.getFieldValue('analog_pins');
    var code = `${dropdown_pins}`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['tf'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var dropdown_tf = block.getFieldValue('tf');
    var code = `${dropdown_tf}`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['traffic_light_set_light'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_red = pythonGenerator.valueToCode(block, 'red', Order.ATOMIC);
    var value_yellow = pythonGenerator.valueToCode(block, 'yellow', Order.ATOMIC);
    var value_green = pythonGenerator.valueToCode(block, 'green', Order.ATOMIC);
    microPythonResolver.add_import_statement("from machine import Pin\n");

    var code = `red.value(${value_red})
yellow.value(${value_yellow})
green.value(${value_green})\n`;
    return code;
};


pythonGenerator.forBlock['traffic_light_upgraded'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin1 = pythonGenerator.valueToCode(block, 'pin1', Order.ATOMIC);
    var value_value1 = pythonGenerator.valueToCode(block, 'value1', Order.ATOMIC);
    var value_pin2 = pythonGenerator.valueToCode(block, 'pin2', Order.ATOMIC);
    var value_value2 = pythonGenerator.valueToCode(block, 'value2', Order.ATOMIC);
    var value_pin3 = pythonGenerator.valueToCode(block, 'pin3', Order.ATOMIC);
    var value_value3 = pythonGenerator.valueToCode(block, 'value3', Order.ATOMIC);
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`red${value_pin1} = Pin(${value_pin1},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`yellow${value_pin2} = Pin(${value_pin2},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`green${value_pin3} = Pin(${value_pin3},Pin.OUT)`);
    var code = `red${value_pin1}.value(${value_value1})
yellow${value_pin2}.value(${value_value2})
green${value_pin3}.value(${value_value3})\n`;
    return code;
};


pythonGenerator.forBlock['digital_write'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    var value_val = pythonGenerator.valueToCode(block, 'val', Order.ATOMIC);
    microPythonResolver.add_import_statement("from machine import Pin");
    microPythonResolver.add_initialization_statement(`digitalwrite = Pin(${value_pin},Pin.OUT)`);
    var code = `digitalwrite.value(${value_val})\n`;
    return code;
};



pythonGenerator.forBlock['analog_write'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    var value_val = pythonGenerator.valueToCode(block, 'val', Order.ATOMIC);
    microPythonResolver.add_import_statement("from machine import Pin");
    microPythonResolver.add_import_statement("from machine import PWM");
    microPythonResolver.add_initialization_statement(`frequency = 50`);
    microPythonResolver.add_initialization_statement(`analog${value_pin} = PWM(Pin(${value_pin}), frequency)`);
    var code = `analog${value_pin}.duty(${value_val})\n`;
    return code;
};



pythonGenerator.forBlock['print_digital_input_reading'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`digitalRead${value_name}= Pin(${value_name}, Pin.IN)`);
    var code = `uart.write(digitalRead${value_name}.value())\n`;
    return code;
};



pythonGenerator.forBlock['touch_sensor_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`touch_sensor${value_pin}= Pin(${value_pin}, Pin.IN)`);
    var code = `touch_sensor${value_pin}.value()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['light_sensor_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`LDR_sensor${value_pin}= Pin(${value_pin}, Pin.IN)`);
    var code = `LDR_sensor${value_pin}.value()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['ir_sensor_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`IR_sensor${value_pin}= Pin(${value_pin}, Pin.IN)`);
    var code = `IR_sensor${value_pin}.value()`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['mq2_digital_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`MQ2_Sensor_Digital= Pin(${value_pin}, Pin.IN)`);
    var code = `MQ2_Sensor_Digital.value()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['mq2_analog_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin,ADC');
    microPythonResolver.add_import_statement('from mq2 import MQ2');
    microPythonResolver.add_initialization_statement(`MQ2_Sensor_Analog=  MQ2(pinData = Pin(${value_pin},Pin.IN), baseVoltage = 3.3)\nMQ2_Sensor_Analog.calibrate()`);
    var code = ``;
    return [code, Order.ATOMIC];
};



pythonGenerator.forBlock['digital_pin_pin'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`digitalRead${value_name}= Pin(${value_name}, Pin.IN)`);
    var code = `digitalRead${value_name}.value()`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['mq2_lpg_percentage'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `MQ2_Sensor_Analog.readLPG()`;
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['mq2_smoke_percentage'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `MQ2_Sensor_Analog.readSmoke()`;
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['mq2_hydrogen_percentage'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `MQ2_Sensor_Analog.readHydrogen()`;
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['mq2_methane_percentage'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `MQ2_Sensor_Analog.readMethane()`;
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['analog_input_pin'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import ADC');
    microPythonResolver.add_initialization_statement(`AdcRead${value_name}= ADC(Pin(${value_name}))`);
    var code = `AdcRead${value_name}.read()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['input_pullup_pin'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`digitalRead${value_name}= Pin(${value_name}, Pin.IN, Pin.PULL_UP)`);
    var code = `digitalRead${value_name}.value()`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['print_analog_input_reading'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import ADC');
    microPythonResolver.add_initialization_statement(`AdcRead${value_name}= ADC(Pin(${value_name}))`);
    var code = `uart.write(AdcRead${value_name}.read())\n`;
    return code;
};


pythonGenerator.forBlock['pot_value'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import ADC');
    microPythonResolver.add_initialization_statement(`PotRead${value_name}= ADC(Pin(${value_name}))`);
    var code = `PotRead${value_name}.read()`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['joystick_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_x = pythonGenerator.valueToCode(block, 'x', Order.ATOMIC);
    var value_y = pythonGenerator.valueToCode(block, 'y', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import ADC');
    microPythonResolver.add_initialization_statement(`jsX= ADC(Pin(${value_x},Pin.IN))`);
    microPythonResolver.add_initialization_statement(`jsY= ADC(Pin(${value_y},Pin.IN))`);
    microPythonResolver.add_initialization_statement(`jsX.atten(ADC.ATTN_11DB)`);
    microPythonResolver.add_initialization_statement(`jsY.atten(ADC.ATTN_11DB)`);
    return ``;
};

pythonGenerator.forBlock['jysX'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'jsX.read()';
    return [code, Order.ATOMIC];
};
pythonGenerator.forBlock['jysY'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'jsY.read()';
    return [code, Order.ATOMIC];
};



pythonGenerator.forBlock['define_dht_pin'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var dropdown_dht = block.getFieldValue('dht');
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('import dht');
    microPythonResolver.add_initialization_statement(`DHT_sensor = dht.DHT${dropdown_dht}(Pin(${value_pin}))`);
    var code = ``;
    return code;
};




pythonGenerator.forBlock['read_humidity'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `DHT_sensor.humidity()`;
    return [code, Order.ATOMIC];
};





pythonGenerator.forBlock['read_temp_c'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `DHT_sensor.temperature()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['read_temp_f'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `(DHT_sensor.temperature() * 9 / 5) + 32.0`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['define_ultrasonic_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_trig = pythonGenerator.valueToCode(block, 'trig', Order.ATOMIC);
    var value_echo = pythonGenerator.valueToCode(block, 'echo', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from hcsr04 import HCSR04');
    microPythonResolver.add_import_statement('from time import sleep');
    microPythonResolver.add_initialization_statement(`Ultrasonic_sensor = HCSR04(trigger_pin=${value_trig}, echo_pin=${value_echo}, echo_timeout_us=10000)`);
    var code = ``;
    return code;
};


pythonGenerator.forBlock['dist_cm'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `Ultrasonic_sensor.distance_cm()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['keypad_row_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = ``;
    return code;
};

pythonGenerator.forBlock['keypad_col_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = ``;
    return code;
};


pythonGenerator.forBlock['initialize_keypad'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from time import sleep');
    microPythonResolver.add_import_statement('import utime');
    var value_p1 = pythonGenerator.valueToCode(block, 'p1', Order.ATOMIC);
    var value_p2 = pythonGenerator.valueToCode(block, 'p2', Order.ATOMIC);
    var value_p3 = pythonGenerator.valueToCode(block, 'p3', Order.ATOMIC);
    var value_p4 = pythonGenerator.valueToCode(block, 'p4', Order.ATOMIC);
    // import statements
    microPythonResolver.add_initialization_statement(`rows = [${value_p1},${value_p2},${value_p3},${value_p4}]`);
    var value_p5 = pythonGenerator.valueToCode(block, 'p5', Order.ATOMIC);
    var value_p6 = pythonGenerator.valueToCode(block, 'p6', Order.ATOMIC);
    var value_p7 = pythonGenerator.valueToCode(block, 'p7', Order.ATOMIC);
    var value_p8 = pythonGenerator.valueToCode(block, 'p9', Order.ATOMIC);
    microPythonResolver.add_initialization_statement(`cols = [${value_p5},${value_p6},${value_p7},${value_p8}]`);
    microPythonResolver.add_initialization_statement(`KEY_UP   = const(0)
KEY_DOWN = const(1)
`);
    microPythonResolver.add_initialization_statement(`row_pins = [Pin(pin_name, mode=Pin.OUT) for pin_name in rows]
col_pins = [Pin(pin_name, mode=Pin.IN, pull=Pin.PULL_DOWN) for pin_name in cols]
def init():
    for row in range(0,4):
        for col in range(0,4):
            row_pins[row].value(0)
def scan(row, col):
    row_pins[row].value(1)
    key = None
    if col_pins[col].value() == KEY_DOWN:
        key = KEY_DOWN
    if col_pins[col].value() == KEY_UP:
        key = KEY_UP
    row_pins[row].value(0)
def readKey():
    lastKeypadRead = ""
    for row in range(4):
        for col in range(4):
            key = scan(row, col)
            if key == KEY_DOWN:
                lastKeypadRead=keys[row][col]
                time.sleep(0.5)
    return lastKeypadRead`);
    var code = ``;
    return code;
};


pythonGenerator.forBlock['readkey'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `readKey()`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['key_press_detected_in'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    var code = `#No Code Given`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['moisture_sensor_input'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`moisture_sensor= Pin(${value_pin}, Pin.IN)`);

    var code = `moisture_sensor.value()`;
    return [code, Order.ATOMIC];
};



pythonGenerator.forBlock['pump_motor'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin1 = pythonGenerator.valueToCode(block, 'pin1', Order.ATOMIC);
    var value_pin2 = pythonGenerator.valueToCode(block, 'pin2', Order.ATOMIC);
    var value_value1 = pythonGenerator.valueToCode(block, 'value1', Order.ATOMIC);
    var value_value2 = pythonGenerator.valueToCode(block, 'value2', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`P${value_pin1}pumpM = Pin(${value_pin1},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`P${value_pin2}pumpM = Pin(${value_pin2},Pin.OUT)`);

    var code = `P${value_pin1}pumpM.value(${value_value1})
 P${value_pin2}pumpM.value(${value_value2})\n`;
    return code;
};

pythonGenerator.forBlock['init_i2c_lcd'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_addr_ = pythonGenerator.valueToCode(block, 'addr#', Order.ATOMIC);



    microPythonResolver.add_import_statement('from time import sleep_ms');
    microPythonResolver.add_import_statement('from time import ticks_ms');
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import I2C');
    microPythonResolver.add_import_statement('from i2c_lcd import I2cLcd');

    microPythonResolver.add_initialization_statement(`
DEFAULT_I2C_ADDR = 0x27

i2c = I2C(scl=Pin(22), sda=Pin(21), freq=400000) 
lcd = I2cLcd(i2c, DEFAULT_I2C_ADDR, 2, 16)`);
    var code = '';
    return code;
};

pythonGenerator.forBlock['i2c_clear_lcd_display'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'lcd.clear()\n';
    return code;
};

pythonGenerator.forBlock['i2c_lcd_print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_r = pythonGenerator.valueToCode(block, 'r', Order.ATOMIC);
    var value_c = pythonGenerator.valueToCode(block, 'c', Order.ATOMIC);
    var value_t = pythonGenerator.valueToCode(block, 't', Order.ATOMIC);
    var code = `lcd.move_to(${value_r},${value_c})
lcd.putstr(str(${value_t}))
lcd.hide_cursor()\n`;
    return code;
};



pythonGenerator.forBlock['eight_segment_display_define'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_clk = pythonGenerator.valueToCode(block, 'clk', Order.ATOMIC);
    var value_dio = pythonGenerator.valueToCode(block, 'dio', Order.ATOMIC);
    microPythonResolver.add_import_statement('import tm1637');
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_initialization_statement(`tm = tm1637.TM1637(dio=Pin(${value_dio}), clk=Pin(${value_clk}))`);
    var code = '';
    return code;
};

pythonGenerator.forBlock['i2c_lcd_brightness_level'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_number = pythonGenerator.valueToCode(block, 'number', Order.ATOMIC);
    var value_colon = pythonGenerator.valueToCode(block, 'colon', Order.ATOMIC);
    var value_lead_0 = pythonGenerator.valueToCode(block, 'lead_0', Order.ATOMIC);
    var code = `tm.brightness(${value_number})\n`;
    return code;
};

pythonGenerator.forBlock['dotmatrix_pins'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_din = pythonGenerator.valueToCode(block, 'din', Order.ATOMIC);
    var value_cs = pythonGenerator.valueToCode(block, 'cs', Order.ATOMIC);
    var value_clk = pythonGenerator.valueToCode(block, 'clk', Order.ATOMIC);

    microPythonResolver.add_import_statement('import max7219');
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import SPI');
    microPythonResolver.add_import_statement('import machine');
    microPythonResolver.add_import_statement('import time');

    var code = 'display.fill(0)\n';
    return code;
};



pythonGenerator.forBlock['dotmatrix_display'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_row1 = pythonGenerator.valueToCode(block, 'ROW1', Order.ATOMIC);
    var value_row2 = pythonGenerator.valueToCode(block, 'ROW2', Order.ATOMIC);
    var value_row3 = pythonGenerator.valueToCode(block, 'ROW3', Order.ATOMIC);
    var value_row4 = pythonGenerator.valueToCode(block, 'ROW4', Order.ATOMIC);
    var value_row5 = pythonGenerator.valueToCode(block, 'ROW5', Order.ATOMIC);
    var value_row6 = pythonGenerator.valueToCode(block, 'ROW6', Order.ATOMIC);
    var value_row7 = pythonGenerator.valueToCode(block, 'ROW7', Order.ATOMIC);
    var value_row8 = pythonGenerator.valueToCode(block, 'ROW8', Order.ATOMIC);
    microPythonResolver.add_initialization_statement(`
Row0 =${value_row1}
Row1 =${value_row2}
Row2 =${value_row3}
Row3 =${value_row4}
Row4 =${value_row5}
Row5 =${value_row6}
Row6 =${value_row7}
Row7 =${value_row8}
ByteArray = [Row0,Row1,Row2,Row3,Row4,Row5,Row6,Row7]`);

    var code = `
for row in range(0,8):
    for column in range(0,8):
        display.pixel(row,column,int(ByteArray[column][row]))
display.show()`;
    return code;
};


pythonGenerator.forBlock['i2c_lcd_set_row'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_r = pythonGenerator.valueToCode(block, 'r', Order.ATOMIC);
    var value_v = pythonGenerator.valueToCode(block, 'v', Order.ATOMIC);
    var code = '...\n';
    return code;
};

pythonGenerator.forBlock['i2c_lcd_set_col'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_c = pythonGenerator.valueToCode(block, 'c', Order.ATOMIC);
    var value_v = pythonGenerator.valueToCode(block, 'v', Order.ATOMIC);
    var code = '...\n';
    return code;
};


pythonGenerator.forBlock['i2c_lcd_set_pixel'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_r = pythonGenerator.valueToCode(block, 'r', Order.ATOMIC);
    var value_c = pythonGenerator.valueToCode(block, 'c', Order.ATOMIC);
    var value_v = pythonGenerator.valueToCode(block, 'v', Order.ATOMIC);
    var code = `display.pixel(${value_r}, ${value_c},${value_v}))`;
    return code;
};



pythonGenerator.forBlock['init_rfid'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_sda = pythonGenerator.valueToCode(block, 'SDA', Order.ATOMIC);
    var value_rst = pythonGenerator.valueToCode(block, 'RST', Order.ATOMIC);

    microPythonResolver.add_import_statement('from mfrc522 import MFRC522');
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import SPI');


    microPythonResolver.add_initialization_statement(`
spi = SPI(2, baudrate=2500000, polarity=0, phase=0)
# Using Hardware SPI pins:
#     sck=18   # yellow
#     mosi=23  # orange
#     miso=19  # blue
#     rst=4    # white
#     cs=5     # green, DS
# *************************

spi.init()
rdr = MFRC522(spi=spi, gpioRstPin=${value_rst}, gpioCsPin=${value_sda})
`);
    var code = '';
    return code;
};



pythonGenerator.forBlock['card_present'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_initialization_statement(`
def IsCard():        
(stat, tag_type) = rdr.request(rdr.REQIDL)
    if stat == rdr.OK:
        return True
    else :
        return False`);
    var code = 'IsCard()\n';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['read_rfid_card'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_initialization_statement(`
def ReadCard():
    (stat, tag_type) = rdr.request(rdr.REQIDL)
    if stat == rdr.OK:
        (stat, raw_uid) = rdr.anticoll()
        if stat == rdr.OK:
            card_id = "uid: 0x%02x%02x%02x%02x" % (raw_uid[0], raw_uid[1], raw_uid[2], raw_uid[3])
            return card_id`);

    var code = 'ReadCard()\n';
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['print_id'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    microPythonResolver.add_initialization_statement(`
def PrintId():
(stat, tag_type) = rdr.request(rdr.REQIDL)
    if stat == rdr.OK:
        (stat, raw_uid) = rdr.anticoll()
        if stat == rdr.OK:
            card_id = "uid: 0x%02x%02x%02x%02x" % (raw_uid[0], raw_uid[1], raw_uid[2], raw_uid[3])
            print(card_id)`);

    var code = 'PrintId()';
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['id_number'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);

    microPythonResolver.add_initialization_statement(`
def PrintSingleIdNumber(${value_name}):
(stat, tag_type) = rdr.request(rdr.REQIDL)
    if stat == rdr.OK:
        (stat, raw_uid) = rdr.anticoll()
        if stat == rdr.OK:
            id_number = "%02x" % (raw_uid[${value_name}])
            print(id_number)`);

    var code = `PrintSingleIdNumber(${value_name})\n`;
    return [code, Order.ATOMIC];
};

pythonGenerator.forBlock['initialize_accelerometer'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    microPythonResolver.add_initialization_statement('i2c = I2C(scl=Pin(22), sda=Pin(21)) #initializing the I2C method for ESP32');
    var code = 'mpu= mpu6050.accel(i2c)\n';
    return code;
};


pythonGenerator.forBlock['read_xyz_values'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_x = pythonGenerator.valueToCode(block, 'x', Order.ATOMIC);
    var value_y = pythonGenerator.valueToCode(block, 'y', Order.ATOMIC);
    var value_z = pythonGenerator.valueToCode(block, 'z', Order.ATOMIC);
    var code = `
mpu.get_values()
print(mpu.get_values())\n`;
    return code;
};

pythonGenerator.forBlock['remote_receiver'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);

    microPythonResolver.add_import_statement('import time');
    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from ir_rx import NEC_16');

    var code = `SignalPin = ${value_pin}\n`;
    return code;
};


pythonGenerator.forBlock['remote_button_pressed'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_initialization_statement(`
def callback(data, addr, ctrl):
if data > 0:  # NEC protocol sends repeat codes.
    print('Data {:02x} Addr {:04x}'.format(data, addr))
    `);

    var code = 'ir = NEC_16(Pin(SignalPin , Pin.IN), callback)';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['button_code'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    microPythonResolver.add_initialization_statement(`ir_key = {
    0x45: 'POWER',
    0x46: 'MODE',
    0x47: 'MUTE',
    0x44: 'PLAY',
    0x40: 'PREV',
    0x43: 'NEXT',
    0x07: 'EQ',
    0x15: 'MINUS',
    0x09: 'PLUS',
    0x16: '0',
    0x19: 'REPEAT',
    0x0D: 'USD',
    0x0C: '1',
    0x18: '2',
    0x5E: '3',
    0x08: '4',
    0x1C: '5',
    0x5A: '6',
    0x42: '7',
    0x52: '8',
    0x4A: '9'    
    }

def readButton(data, addr, ctrl):
    if data > 0:  # NEC protocol sends repeat codes.
        return (ir_key[data])
    `);
    var code = 'ir = NEC_16(Pin(SignalPin , Pin.IN), readButton)\n';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['serial_print_button_value'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_initialization_statement(`
ir_key = {
    0x45: 'POWER',
    0x46: 'MODE',
    0x47: 'MUTE',
    0x44: 'PLAY',
    0x40: 'PREV',
    0x43: 'NEXT',
    0x07: 'EQ',
    0x15: 'MINUS',
    0x09: 'PLUS',
    0x16: '0',
    0x19: 'REPEAT',
    0x0D: 'USD',
    0x0C: '1',
    0x18: '2',
    0x5E: '3',
    0x08: '4',
    0x1C: '5',
    0x5A: '6',
    0x42: '7',
    0x52: '8',
    0x4A: '9'    
    }

def PrintButton(data, addr, ctrl):
    if data > 0:  # NEC protocol sends repeat codes.
        print(ir_key[data])
    `);
    var code = 'ir = NEC_16(Pin(SignalPin , Pin.IN), PrintButton)\n';
    return code;
};


pythonGenerator.forBlock['init_colour_sensor'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_s0 = pythonGenerator.valueToCode(block, 's0', Order.ATOMIC);
    var value_s1 = pythonGenerator.valueToCode(block, 's1', Order.ATOMIC);
    var value_s2 = pythonGenerator.valueToCode(block, 's2', Order.ATOMIC);
    var value_s3 = pythonGenerator.valueToCode(block, 's3', Order.ATOMIC);
    var value_out = pythonGenerator.valueToCode(block, 'out', Order.ATOMIC);

    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from time import sleep');
    microPythonResolver.add_import_statement('import utime');

    microPythonResolver.add_initialization_statement(`s0 = Pin(${value_s0}, Pin.OUT)
s1 = Pin(${value_s1}, Pin.OUT)
s2 = Pin(${value_s2}, Pin.OUT)
s3 = Pin(${value_s3}, Pin.OUT)
ouT = Pin(${value_out}, Pin.IN)
frequency = 0
# sensor output frequency setting (2% of maximum)
s0.on()
s1.on()

def get_frequency():
    frequency = machine .time_pulse_us(ouT,0)
    utime.sleep_ms(20)
    return frequency`);
    var code = '';
    return code;
};


pythonGenerator.forBlock['activate_red_mode'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `s2.value(0) # s2.off() 
s3.value(0)\n`;
    return code;
};

pythonGenerator.forBlock['activate_green_mode'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `s2.value(1) # s2.on()
s3.value(1)\n`;
    return code;
};

pythonGenerator.forBlock['activate_blue_mode'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `s2.value(0)
s3.value(1)\n`;
    return code;
};


pythonGenerator.forBlock['read_colour_intensity'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'variable= get_frequency()\n';
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['initialize_bt'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_tx = pythonGenerator.valueToCode(block, 'tx', Order.ATOMIC);
    var value_rx = pythonGenerator.valueToCode(block, 'rx', Order.ATOMIC);
    var value_baud = pythonGenerator.valueToCode(block, 'baud', Order.ATOMIC);

    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import Timer');
    microPythonResolver.add_import_statement('from time import sleep_ms');
    microPythonResolver.add_import_statement('import ubluetooth');

    microPythonResolver.add_initialization_statement(`
ble_msg = ""
is_ble_connected = False

class ESP32_BLE():
    def __init__(self, name):
        # Create internal objects for the onboard LED
        # blinking when no BLE device is connected
        # stable ON when connected
        self.led = Pin(2, Pin.OUT)
        self.timer1 = Timer(0)
        
        self.name = name
        self.ble = ubluetooth.BLE()
        self.ble.active(True)
        self.disconnected()
        self.ble.irq(self.ble_irq)
        self.register()
        self.advertiser()

    def connected(self):
        global is_ble_connected
        is_ble_connected = True
        self.led.value(1)
        self.timer1.deinit()

    def disconnected(self):
        global is_ble_connected
        is_ble_connected = False
        self.timer1.init(period=100, mode=Timer.PERIODIC, callback=lambda t: self.led.value(not self.led.value()))

    def ble_irq(self, event, data):
        global ble_msg
        
        if event == 1: #_IRQ_CENTRAL_CONNECT:
                        # A central has connected to this peripheral
            self.connected()

        elif event == 2: #_IRQ_CENTRAL_DISCONNECT:
                            # A central has disconnected from this peripheral.
            self.advertiser()
            self.disconnected()
        
        elif event == 3: #_IRQ_GATTS_WRITE:
                            # A client has written to this characteristic or descriptor.          
            buffer = self.ble.gatts_read(self.rx)
            ble_msg = buffer.decode('UTF-8').strip()
            
    def register(self):        
        # Nordic UART Service (NUS)
        NUS_UUID = '6E400001-B5A3-F393-E0A9-E50E24DCCA9E'
        RX_UUID = '6E400002-B5A3-F393-E0A9-E50E24DCCA9E'
        TX_UUID = '6E400003-B5A3-F393-E0A9-E50E24DCCA9E'
            
        BLE_NUS = ubluetooth.UUID(NUS_UUID)
        BLE_RX = (ubluetooth.UUID(RX_UUID), ubluetooth.FLAG_WRITE)
        BLE_TX = (ubluetooth.UUID(TX_UUID), ubluetooth.FLAG_NOTIFY)
            
        BLE_UART = (BLE_NUS, (BLE_TX, BLE_RX,))
        SERVICES = (BLE_UART, )
        ((self.tx, self.rx,), ) = self.ble.gatts_register_services(SERVICES)

    def send(self, data):
        self.ble.gatts_notify(0, self.tx, data + '\n')

    def advertiser(self):
        name = bytes(self.name, 'UTF-8')
        adv_data = bytearray('\x02\x01\x02') + bytearray((len(name) + 1, 0x09)) + name
        self.ble.gap_advertise(100, adv_data)
        print(adv_data)
        print("\r\n")
                # adv_data
                # raw: 0x02010209094553503332424C45
                # b'\x02\x01\x02\t\tESP32BLE'
                #
                # 0x02 - General discoverable mode
                # 0x01 - AD Type = 0x01
                # 0x02 - value = 0x02
                
                # https://jimmywongiot.com/2019/08/13/advertising-payload-format-on-ble/
                # https://docs.silabs.com/bluetooth/latest/general/adv-and-scanning/bluetooth-adv-data-basics


ble = ESP32_BLE("ESP32BLE")`);

    var code = 'ble = ESP32_BLE("ESP32BLE")\n';
    return code;
};



pythonGenerator.forBlock['bt_available'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'is_ble_connected';
    return [code, Order.NONE];
};


pythonGenerator.forBlock['bt_read'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'variable = ble_msg ';
    return [code, Order.NONE];
};



pythonGenerator.forBlock['bt_read_str'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = 'variable = str(ble_msg )';
    return [code, Order.NONE];
};

pythonGenerator.forBlock['bt_print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_name = pythonGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    var dropdown_name = block.getFieldValue('NAME');
    var code = 'ble.send("String")\n';
    return code;
};


pythonGenerator.forBlock['end_of_print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = "ble.send('\n')";
    return code;
};









pythonGenerator.forBlock['servo'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value = pythonGenerator.valueToCode(block, 'pin', Order.ATOMIC);
    console.log("", value);

    if (!value) {
        value = "2"
    }
    microPythonResolver.add_import_statement(`from servo import Servo`)
    microPythonResolver.add_initialization_statement(`motor${value}=Servo(pin=${value})`)
    var degree = pythonGenerator.valueToCode(block, 'degree', Order.ATOMIC);
    var code = `motor${value}.move(${degree})\n`;
    return code;
};










pythonGenerator.forBlock['move_servo'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    var value = pythonGenerator.valueToCode(block, 'degree', Order.ATOMIC);
    var code = `motor.move(${value})\n`;
    return code;
};










pythonGenerator.forBlock['8x8_matrix'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = "";
    var value = pythonGenerator.valueToCode(block, 'number_of_pins', Order.ATOMIC);
    if (!value) {
        value = "1"
    }
    microPythonResolver.add_import_statement(`import max7219`)
    microPythonResolver.add_import_statement(`from machine import Pin`)
    microPythonResolver.add_import_statement(`from machine import SPI`)
    microPythonResolver.add_initialization_statement(`
spi = SPI(1, baudrate=10000000, polarity=1, phase=0, sck=Pin(4), mosi=Pin(2))
ss = Pin(5, Pin.OUT)
display = max7219.Matrix8x8(spi, ss, ${value})
display.fill(0)
`)
    return code;
};







pythonGenerator.forBlock['print_on_matrix'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var string = pythonGenerator.valueToCode(block, 'value', Order.ATOMIC);
    var column = pythonGenerator.valueToCode(block, 'column', Order.ATOMIC);
    if (!column) {
        column = "1"
    }
    let code = `display.text(${string},0,0,${column})\ndisplay.show()\n`;
    return code;
};



pythonGenerator.forBlock['clear_8x8_matrix'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    let code = `display.fill(0)\n`;
    return code;
};




pythonGenerator.forBlock['initialize_IOT'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    var api_key = pythonGenerator.valueToCode(block, 'api_key', Order.ATOMIC);
    var project_secret_key = pythonGenerator.valueToCode(block, 'project_secret_key', Order.ATOMIC);
    var ssid = pythonGenerator.valueToCode(block, 'ssid', Order.ATOMIC);
    var password = pythonGenerator.valueToCode(block, 'password', Order.ATOMIC);
    console.log(api_key, project_secret_key, ssid, password);
    microPythonResolver.add_import_statement(`import educobot_iot`)
    microPythonResolver.add_initialization_statement(`iot = educobot_iot.eduCOBOT_IOT(secretKey=${project_secret_key}, APIKey = ${api_key}, wifiName = ${ssid}, wifiPassword = ${password})`)
    let code = ``;
    return code;
};




pythonGenerator.forBlock['send_data_iot'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var name = pythonGenerator.valueToCode(block, 'name', Order.ATOMIC);
    var value = pythonGenerator.valueToCode(block, 'value', Order.ATOMIC);

    let code = `iot.sendData(variableName=${name},variableValue=${value})\n`;
    return code;
};




pythonGenerator.forBlock['get_data_iot'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var name = pythonGenerator.valueToCode(block, 'name', Order.ATOMIC);

    let code = `iot.readData(variableName=${name})`;
    return [code, Order.ATOMIC];
};


pythonGenerator.forBlock['show_on_7_segment_display'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var name = pythonGenerator.valueToCode(block, 'text', Order.ATOMIC);

    let code = `tm.show('${name}')\n`;
    return code;
};

pythonGenerator.forBlock['8-pin-seven-segment'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_pin1 = pythonGenerator.valueToCode(block, 'pin1', Order.ATOMIC);
    var value_value1 = pythonGenerator.valueToCode(block, 'value1', Order.ATOMIC);
    var value_pin2 = pythonGenerator.valueToCode(block, 'pin2', Order.ATOMIC);
    var value_value2 = pythonGenerator.valueToCode(block, 'value2', Order.ATOMIC);
    var value_pin3 = pythonGenerator.valueToCode(block, 'pin3', Order.ATOMIC);
    var value_value3 = pythonGenerator.valueToCode(block, 'value3', Order.ATOMIC);
    var value_pin4 = pythonGenerator.valueToCode(block, 'pin4', Order.ATOMIC);
    var value_value4 = pythonGenerator.valueToCode(block, 'value4', Order.ATOMIC);
    var value_pin5 = pythonGenerator.valueToCode(block, 'pin5', Order.ATOMIC);
    var value_value5 = pythonGenerator.valueToCode(block, 'value5', Order.ATOMIC);
    var value_pin6 = pythonGenerator.valueToCode(block, 'pin6', Order.ATOMIC);
    var value_value6 = pythonGenerator.valueToCode(block, 'value6', Order.ATOMIC);
    var value_pin7 = pythonGenerator.valueToCode(block, 'pin7', Order.ATOMIC);
    var value_value7 = pythonGenerator.valueToCode(block, 'value7', Order.ATOMIC);
    //import statements
    microPythonResolver.add_import_statement("from machine import Pin\n");
    microPythonResolver.add_initialization_statement(`Pin${value_pin1} = Pin(${value_pin1},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin2} = Pin(${value_pin2},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin3} = Pin(${value_pin3},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin4} = Pin(${value_pin4},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin5} = Pin(${value_pin5},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin6} = Pin(${value_pin6},Pin.OUT)`);
    microPythonResolver.add_initialization_statement(`Pin${value_pin7} = Pin(${value_pin7},Pin.OUT)`);

    var code = `Pin${value_pin1}.value(${value_value1})
Pin${value_pin2}.value(${value_value2})
Pin${value_pin3}.value(${value_value3})
Pin${value_pin4}.value(${value_value4})
Pin${value_pin5}.value(${value_value5})
Pin${value_pin6}.value(${value_value6})
Pin${value_pin7}.value(${value_value7})\n`;
    return code;
};







pythonGenerator.forBlock['init_i2c_OLED'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import I2C');
    microPythonResolver.add_import_statement('import ssd1306');
    microPythonResolver.add_import_statement('from i2c_lcd import I2cLcd');

    microPythonResolver.add_initialization_statement(`i2c_oled = I2C(-1, scl=Pin(21), sda=Pin(22))
oled_width = 128
oled_height = 64
oled = ssd1306.SSD1306_I2C(oled_width, oled_height, i2c_oled)`);
    var code = '';
    return code;
};

pythonGenerator.forBlock['init_i2c_adxl345'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {

    microPythonResolver.add_import_statement('from machine import Pin');
    microPythonResolver.add_import_statement('from machine import I2C');
    microPythonResolver.add_import_statement('import adxl345');
    microPythonResolver.add_import_statement('import time');

    microPythonResolver.add_initialization_statement(`i2c_adxl345 = I2C(scl=Pin(22),sda=Pin(21), freq=10000)
adxl345 = adxl345.ADXL345(i2c=i2c_adxl345)`);
    var code = '';
    return code;
};


pythonGenerator.forBlock['adxl345_result'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var code = `x=adx.xValue\ny=adx.yValue\nz=adx.zValue\nroll,pitch = adx.RP_calculate(x,y,z)`;
    return code;
};



pythonGenerator.forBlock['i2c_OLED_print'] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
    var value_r = pythonGenerator.valueToCode(block, 'r', Order.ATOMIC);
    var value_c = pythonGenerator.valueToCode(block, 'c', Order.ATOMIC);
    var value_t = pythonGenerator.valueToCode(block, 't', Order.ATOMIC);
    var code = `oled.text('${value_t || "zero"}',${value_r || 0},${value_c || 0})\n`;
    return code;
};









export function addDynamicTextReturningBlocks(blocksData: {
    name: string,
    value: string
}[], Old_ToolBox: typeof MicroPythonToolBox, group: string, colour: string, addAfter: string) {
    let new_tool_box = Old_ToolBox

    let resourcesContent = [] as {
        kind: string,
        type: string
    }[]
    blocksData.forEach(blockData => {
        Blockly.Blocks[blockData.name] = {
            init: function () {
                this.appendDummyInput()
                    .appendField(blockData.name);
                this.setOutput(true, "String");
                this.setColour(colour);
                this.setTooltip("");
                this.setHelpUrl("");
            }
        };
        pythonGenerator.forBlock[blockData.name] = function (block: Blockly.Block, pythonGenerator: PythonGenerator) {
            return [`'${blockData.value}'`, Order.ATOMIC]
        };
        resourcesContent.push({
            "kind": "BLOCK",
            "type": blockData.name
        })
    });
    let final_group_content = {
        "kind": "CATEGORY",
        "contents": resourcesContent,
        "name": group,
        "colour": colour
    }
    let index = -1
    for (let i = 0; i < new_tool_box.contents.length; i++) {
        if (new_tool_box.contents[i].name === group) {
            index = i;
            break
        }
    }
    if (index !== -1) {
        new_tool_box.contents[index] = final_group_content
    } else {
        for (let i = 0; i < new_tool_box.contents.length; i++) {
            if (new_tool_box.contents[i].name === addAfter) {
                new_tool_box.contents = [
                    ...new_tool_box.contents.slice(0, i + 1),
                    final_group_content,
                    ...new_tool_box.contents.slice(i + 1)
                ]
                break
            }
        }
    }

    return new_tool_box
}
























export default pythonGenerator
























