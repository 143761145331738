import Blockly from './javascript-blocks';
import { JavascriptGenerator, Order } from 'blockly/javascript';

const stringUtils = Blockly.utils.string
const NameType = Blockly.Names.NameType;
const Variables = Blockly.Variables;
type Block = Blockly.Block



export function lists_create_empty(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Create an empty list.
    return ['[]', Order.ATOMIC];
}

export function lists_create_with(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Create a list with any number of elements of any type.
    const createWithBlock = block as any;
    const elements = new Array(createWithBlock.itemCount_);
    for (let i = 0; i < createWithBlock.itemCount_; i++) {
        elements[i] = generator.valueToCode(block, 'ADD' + i, Order.NONE) || 'null';
    }
    const code = '[' + elements.join(', ') + ']';
    return [code, Order.ATOMIC];
}

export function lists_repeat(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Create a list with one element repeated.
    const functionName = generator.provideFunction_(
        'listsRepeat',
        `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(value, n) {
    var array = [];
    for (var i = 0; i < n; i++) {
      array[i] = value;
    }
    return array;
  }
  `,
    );
    const element = generator.valueToCode(block, 'ITEM', Order.NONE) || 'null';
    const repeatCount = generator.valueToCode(block, 'NUM', Order.NONE) || '0';
    const code = functionName + '(' + element + ', ' + repeatCount + ')';
    return [code, Order.FUNCTION_CALL];
}

export function lists_length(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // String or array length.
    const list = generator.valueToCode(block, 'VALUE', Order.MEMBER) || '[]';
    return [list + '.length', Order.MEMBER];
}

export function lists_isEmpty(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Is the string null or array empty?
    const list = generator.valueToCode(block, 'VALUE', Order.MEMBER) || '[]';
    return ['!' + list + '.length', Order.LOGICAL_NOT];
}

export function lists_indexOf(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Find an item in the list.
    const operator =
        block.getFieldValue('END') === 'FIRST' ? 'indexOf' : 'lastIndexOf';
    const item = generator.valueToCode(block, 'FIND', Order.NONE) || "''";
    const list = generator.valueToCode(block, 'VALUE', Order.MEMBER) || '[]';
    const code = list + '.' + operator + '(' + item + ')';
    if (block.workspace.options.oneBasedIndex) {
        return [code + ' + 1', Order.ADDITION];
    }
    return [code, Order.FUNCTION_CALL];
}

export function lists_getIndex(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] | string {
    // Get element at index.
    // Note: Until January 2013 this block did not have MODE or WHERE inputs.
    const mode = block.getFieldValue('MODE') || 'GET';
    const where = block.getFieldValue('WHERE') || 'FROM_START';
    const listOrder = where === 'RANDOM' ? Order.NONE : Order.MEMBER;
    const list = generator.valueToCode(block, 'VALUE', listOrder) || '[]';

    switch (where) {
        case 'FIRST':
            if (mode === 'GET') {
                const code = list + '[0]';
                return [code, Order.MEMBER];
            } else if (mode === 'GET_REMOVE') {
                const code = list + '.shift()';
                return [code, Order.MEMBER];
            } else if (mode === 'REMOVE') {
                return list + '.shift();\n';
            }
            break;
        case 'LAST':
            if (mode === 'GET') {
                const code = list + '.slice(-1)[0]';
                return [code, Order.MEMBER];
            } else if (mode === 'GET_REMOVE') {
                const code = list + '.pop()';
                return [code, Order.MEMBER];
            } else if (mode === 'REMOVE') {
                return list + '.pop();\n';
            }
            break;
        case 'FROM_START': {
            const at = generator.getAdjusted(block, 'AT');
            if (mode === 'GET') {
                const code = list + '[' + at + ']';
                return [code, Order.MEMBER];
            } else if (mode === 'GET_REMOVE') {
                const code = list + '.splice(' + at + ', 1)[0]';
                return [code, Order.FUNCTION_CALL];
            } else if (mode === 'REMOVE') {
                return list + '.splice(' + at + ', 1);\n';
            }
            break;
        }
        case 'FROM_END': {
            const at = generator.getAdjusted(block, 'AT', 1, true);
            if (mode === 'GET') {
                const code = list + '.slice(' + at + ')[0]';
                return [code, Order.FUNCTION_CALL];
            } else if (mode === 'GET_REMOVE') {
                const code = list + '.splice(' + at + ', 1)[0]';
                return [code, Order.FUNCTION_CALL];
            } else if (mode === 'REMOVE') {
                return list + '.splice(' + at + ', 1);';
            }
            break;
        }
        case 'RANDOM': {
            const functionName = generator.provideFunction_(
                'listsGetRandomItem',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(list, remove) {
    var x = Math.floor(Math.random() * list.length);
    if (remove) {
      return list.splice(x, 1)[0];
    } else {
      return list[x];
    }
  }
  `,
            );
            const code = functionName + '(' + list + ', ' + (mode !== 'GET') + ')';
            if (mode === 'GET' || mode === 'GET_REMOVE') {
                return [code, Order.FUNCTION_CALL];
            } else if (mode === 'REMOVE') {
                return code + ';\n';
            }
            break;
        }
    }
    throw Error('Unhandled combination (lists_getIndex).');
}

export function lists_setIndex(block: Block, generator: JavascriptGenerator) {
    // Set element at index.
    // Note: Until February 2013 this block did not have MODE or WHERE inputs.
    let list = generator.valueToCode(block, 'LIST', Order.MEMBER) || '[]';
    const mode = block.getFieldValue('MODE') || 'GET';
    const where = block.getFieldValue('WHERE') || 'FROM_START';
    const value = generator.valueToCode(block, 'TO', Order.ASSIGNMENT) || 'null';
    // Cache non-trivial values to variables to prevent repeated look-ups.
    // Closure, which accesses and modifies 'list'.
    function cacheList() {
        if (list.match(/^\w+$/)) {
            return '';
        }
        const listVar = generator.nameDB_!.getDistinctName(
            'tmpList',
            NameType.VARIABLE,
        )!;
        const code = 'var ' + listVar + ' = ' + list + ';\n';
        list = listVar;
        return code;
    }
    switch (where) {
        case 'FIRST':
            if (mode === 'SET') {
                return list + '[0] = ' + value + ';\n';
            } else if (mode === 'INSERT') {
                return list + '.unshift(' + value + ');\n';
            }
            break;
        case 'LAST':
            if (mode === 'SET') {
                let code = cacheList();
                code += list + '[' + list + '.length - 1] = ' + value + ';\n';
                return code;
            } else if (mode === 'INSERT') {
                return list + '.push(' + value + ');\n';
            }
            break;
        case 'FROM_START': {
            const at = generator.getAdjusted(block, 'AT');
            if (mode === 'SET') {
                return list + '[' + at + '] = ' + value + ';\n';
            } else if (mode === 'INSERT') {
                return list + '.splice(' + at + ', 0, ' + value + ');\n';
            }
            break;
        }
        case 'FROM_END': {
            const at = generator.getAdjusted(
                block,
                'AT',
                1,
                false,
                Order.SUBTRACTION,
            );
            let code = cacheList();
            if (mode === 'SET') {
                code += list + '[' + list + '.length - ' + at + '] = ' + value + ';\n';
                return code;
            } else if (mode === 'INSERT') {
                code +=
                    list +
                    '.splice(' +
                    list +
                    '.length - ' +
                    at +
                    ', 0, ' +
                    value +
                    ');\n';
                return code;
            }
            break;
        }
        case 'RANDOM': {
            let code = cacheList();
            const xVar = generator.nameDB_!.getDistinctName(
                'tmpX',
                NameType.VARIABLE,
            );
            code +=
                'var ' + xVar + ' = Math.floor(Math.random() * ' + list + '.length);\n';
            if (mode === 'SET') {
                code += list + '[' + xVar + '] = ' + value + ';\n';
                return code;
            } else if (mode === 'INSERT') {
                code += list + '.splice(' + xVar + ', 0, ' + value + ');\n';
                return code;
            }
            break;
        }
    }
    throw Error('Unhandled combination (lists_setIndex).');
}

/**
 * Returns an expression calculating the index into a list.
 * @param listName Name of the list, used to calculate length.
 * @param where The method of indexing, selected by dropdown in Blockly
 * @param opt_at The optional offset when indexing from start/end.
 * @returns Index expression.
 */
const getSubstringIndex = function (
    listName: string,
    where: string,
    opt_at?: string,
): string | undefined {
    if (where === 'FIRST') {
        return '0';
    } else if (where === 'FROM_END') {
        return listName + '.length - 1 - ' + opt_at;
    } else if (where === 'LAST') {
        return listName + '.length - 1';
    } else {
        return opt_at;
    }
};

export function lists_getSublist(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Get sublist.
    // Dictionary of WHEREn field choices and their CamelCase equivalents.
    const wherePascalCase = {
        'FIRST': 'First',
        'LAST': 'Last',
        'FROM_START': 'FromStart',
        'FROM_END': 'FromEnd',
    };
    type WhereOption = keyof typeof wherePascalCase;
    const list = generator.valueToCode(block, 'LIST', Order.MEMBER) || '[]';
    const where1 = block.getFieldValue('WHERE1') as WhereOption;
    const where2 = block.getFieldValue('WHERE2') as WhereOption;
    let code;
    if (where1 === 'FIRST' && where2 === 'LAST') {
        code = list + '.slice(0)';
    } else if (
        list.match(/^\w+$/) ||
        (where1 !== 'FROM_END' && where2 === 'FROM_START')
    ) {
        // If the list is a variable or doesn't require a call for length, don't
        // generate a helper function.
        let at1;
        switch (where1) {
            case 'FROM_START':
                at1 = generator.getAdjusted(block, 'AT1');
                break;
            case 'FROM_END':
                at1 = generator.getAdjusted(block, 'AT1', 1, false, Order.SUBTRACTION);
                at1 = list + '.length - ' + at1;
                break;
            case 'FIRST':
                at1 = '0';
                break;
            default:
                throw Error('Unhandled option (lists_getSublist).');
        }
        let at2;
        switch (where2) {
            case 'FROM_START':
                at2 = generator.getAdjusted(block, 'AT2', 1);
                break;
            case 'FROM_END':
                at2 = generator.getAdjusted(block, 'AT2', 0, false, Order.SUBTRACTION);
                at2 = list + '.length - ' + at2;
                break;
            case 'LAST':
                at2 = list + '.length';
                break;
            default:
                throw Error('Unhandled option (lists_getSublist).');
        }
        code = list + '.slice(' + at1 + ', ' + at2 + ')';
    } else {
        const at1 = generator.getAdjusted(block, 'AT1');
        const at2 = generator.getAdjusted(block, 'AT2');
        // The value for 'FROM_END' and'FROM_START' depends on `at` so
        // we add it as a parameter.
        const at1Param =
            where1 === 'FROM_END' || where1 === 'FROM_START' ? ', at1' : '';
        const at2Param =
            where2 === 'FROM_END' || where2 === 'FROM_START' ? ', at2' : '';
        const functionName = generator.provideFunction_(
            'subsequence' + wherePascalCase[where1] + wherePascalCase[where2],
            `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_
            }(sequence${at1Param}${at2Param}) {
    var start = ${getSubstringIndex('sequence', where1, 'at1')};
    var end = ${getSubstringIndex('sequence', where2, 'at2')} + 1;
    return sequence.slice(start, end);
  }
  `,
        );
        code =
            functionName +
            '(' +
            list +
            // The value for 'FROM_END' and 'FROM_START' depends on `at` so we
            // pass it.
            (where1 === 'FROM_END' || where1 === 'FROM_START' ? ', ' + at1 : '') +
            (where2 === 'FROM_END' || where2 === 'FROM_START' ? ', ' + at2 : '') +
            ')';
    }
    return [code, Order.FUNCTION_CALL];
}

export function lists_sort(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Block for sorting a list.
    const list =
        generator.valueToCode(block, 'LIST', Order.FUNCTION_CALL) || '[]';
    const direction = block.getFieldValue('DIRECTION') === '1' ? 1 : -1;
    const type = block.getFieldValue('TYPE');
    const getCompareFunctionName = generator.provideFunction_(
        'listsGetSortCompare',
        `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(type, direction) {
    var compareFuncs = {
      'NUMERIC': function(a, b) {
          return Number(a) - Number(b); },
      'TEXT': function(a, b) {
          return String(a) > String(b) ? 1 : -1; },
      'IGNORE_CASE': function(a, b) {
          return String(a).toLowerCase() > String(b).toLowerCase() ? 1 : -1; },
    };
    var compare = compareFuncs[type];
    return function(a, b) { return compare(a, b) * direction; };
  }
        `,
    );
    return [
        list +
        '.slice().sort(' +
        getCompareFunctionName +
        '("' +
        type +
        '", ' +
        direction +
        '))',
        Order.FUNCTION_CALL,
    ];
}

export function lists_split(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Block for splitting text into a list, or joining a list into text.
    let input = generator.valueToCode(block, 'INPUT', Order.MEMBER);
    const delimiter = generator.valueToCode(block, 'DELIM', Order.NONE) || "''";
    const mode = block.getFieldValue('MODE');
    let functionName;
    if (mode === 'SPLIT') {
        if (!input) {
            input = "''";
        }
        functionName = 'split';
    } else if (mode === 'JOIN') {
        if (!input) {
            input = '[]';
        }
        functionName = 'join';
    } else {
        throw Error('Unknown mode: ' + mode);
    }
    const code = input + '.' + functionName + '(' + delimiter + ')';
    return [code, Order.FUNCTION_CALL];
}

export function lists_reverse(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Block for reversing a list.
    const list =
        generator.valueToCode(block, 'LIST', Order.FUNCTION_CALL) || '[]';
    const code = list + '.slice().reverse()';
    return [code, Order.FUNCTION_CALL];
}






export function controls_if(block: Block, generator: JavascriptGenerator) {
    // If/elseif/else condition.
    let n = 0;
    let code = '';
    if (generator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        code += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    do {
        const conditionCode =
            generator.valueToCode(block, 'IF' + n, Order.NONE) || 'false';
        let branchCode = generator.statementToCode(block, 'DO' + n);
        if (generator.STATEMENT_SUFFIX) {
            branchCode =
                generator.prefixLines(
                    generator.injectId(generator.STATEMENT_SUFFIX, block),
                    generator.INDENT,
                ) + branchCode;
        }
        code +=
            (n > 0 ? ' else ' : '') +
            'if (' +
            conditionCode +
            ') {\n' +
            branchCode +
            '}';
        n++;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE') || generator.STATEMENT_SUFFIX) {
        let branchCode = generator.statementToCode(block, 'ELSE');
        if (generator.STATEMENT_SUFFIX) {
            branchCode =
                generator.prefixLines(
                    generator.injectId(generator.STATEMENT_SUFFIX, block),
                    generator.INDENT,
                ) + branchCode;
        }
        code += ' else {\n' + branchCode + '}';
    }
    return code + '\n';
}

export const controls_ifelse = controls_if;

export function logic_compare(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Comparison operator.
    const OPERATORS = {
        'EQ': '==',
        'NEQ': '!=',
        'LT': '<',
        'LTE': '<=',
        'GT': '>',
        'GTE': '>=',
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('OP') as OperatorOption];
    const order =
        operator === '==' || operator === '!=' ? Order.EQUALITY : Order.RELATIONAL;
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}

export function logic_operation(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Operations 'and', 'or'.
    const operator = block.getFieldValue('OP') === 'AND' ? '&&' : '||';
    const order = operator === '&&' ? Order.LOGICAL_AND : Order.LOGICAL_OR;
    let argument0 = generator.valueToCode(block, 'A', order);
    let argument1 = generator.valueToCode(block, 'B', order);
    if (!argument0 && !argument1) {
        // If there are no arguments, then the return value is false.
        argument0 = 'false';
        argument1 = 'false';
    } else {
        // Single missing arguments have no effect on the return value.
        const defaultArgument = operator === '&&' ? 'true' : 'false';
        if (!argument0) {
            argument0 = defaultArgument;
        }
        if (!argument1) {
            argument1 = defaultArgument;
        }
    }
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}

export function logic_negate(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Negation.
    const order = Order.LOGICAL_NOT;
    const argument0 = generator.valueToCode(block, 'BOOL', order) || 'true';
    const code = '!' + argument0;
    return [code, order];
}

export function logic_boolean(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Boolean values true and false.
    const code = block.getFieldValue('BOOL') === 'TRUE' ? 'true' : 'false';
    return [code, Order.ATOMIC];
}

export function logic_null(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Null data type.
    return ['null', Order.ATOMIC];
}

export function logic_ternary(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Ternary operator.
    const value_if =
        generator.valueToCode(block, 'IF', Order.CONDITIONAL) || 'false';
    const value_then =
        generator.valueToCode(block, 'THEN', Order.CONDITIONAL) || 'null';
    const value_else =
        generator.valueToCode(block, 'ELSE', Order.CONDITIONAL) || 'null';
    const code = value_if + ' ? ' + value_then + ' : ' + value_else;
    return [code, Order.CONDITIONAL];
}


export function controls_repeat_ext(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Repeat n times.
    let repeats;
    if (block.getField('TIMES')) {
        // Internal number.
        repeats = String(Number(block.getFieldValue('TIMES')));
    } else {
        // External number.
        repeats = generator.valueToCode(block, 'TIMES', Order.ASSIGNMENT) || '0';
    }
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    let code = '';
    const loopVar = generator.nameDB_!.getDistinctName(
        'count',
        NameType.VARIABLE,
    );
    let endVar = repeats;
    if (!repeats.match(/^\w+$/) && !stringUtils.isNumber(repeats)) {
        endVar = generator.nameDB_!.getDistinctName(
            'repeat_end',
            NameType.VARIABLE,
        );
        code += 'var ' + endVar + ' = ' + repeats + ';\n';
    }
    code +=
        'for (var ' +
        loopVar +
        ' = 0; ' +
        loopVar +
        ' < ' +
        endVar +
        '; ' +
        loopVar +
        '++) {\n' +
        branch +
        '}\n';
    return code;
}

export const controls_repeat = controls_repeat_ext;

export function controls_whileUntil(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Do while/until loop.
    const until = block.getFieldValue('MODE') === 'UNTIL';
    let argument0 =
        generator.valueToCode(
            block,
            'BOOL',
            until ? Order.LOGICAL_NOT : Order.NONE,
        ) || 'false';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    if (until) {
        argument0 = '!' + argument0;
    }
    return 'while (' + argument0 + ') {\n' + branch + '}\n';
}

export function controls_for(block: Block, generator: JavascriptGenerator) {
    // For loop.
    const variable0 = generator.getVariableName(block.getFieldValue('VAR'));
    const argument0 =
        generator.valueToCode(block, 'FROM', Order.ASSIGNMENT) || '0';
    const argument1 = generator.valueToCode(block, 'TO', Order.ASSIGNMENT) || '0';
    const increment = generator.valueToCode(block, 'BY', Order.ASSIGNMENT) || '1';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    let code;
    if (
        stringUtils.isNumber(argument0) &&
        stringUtils.isNumber(argument1) &&
        stringUtils.isNumber(increment)
    ) {
        // All arguments are simple numbers.
        const up = Number(argument0) <= Number(argument1);
        code =
            'for (' +
            variable0 +
            ' = ' +
            argument0 +
            '; ' +
            variable0 +
            (up ? ' <= ' : ' >= ') +
            argument1 +
            '; ' +
            variable0;
        const step = Math.abs(Number(increment));
        if (step === 1) {
            code += up ? '++' : '--';
        } else {
            code += (up ? ' += ' : ' -= ') + step;
        }
        code += ') {\n' + branch + '}\n';
    } else {
        code = '';
        // Cache non-trivial values to variables to prevent repeated look-ups.
        let startVar = argument0;
        if (!argument0.match(/^\w+$/) && !stringUtils.isNumber(argument0)) {
            startVar = generator.nameDB_!.getDistinctName(
                variable0 + '_start',
                NameType.VARIABLE,
            );
            code += 'var ' + startVar + ' = ' + argument0 + ';\n';
        }
        let endVar = argument1;
        if (!argument1.match(/^\w+$/) && !stringUtils.isNumber(argument1)) {
            endVar = generator.nameDB_!.getDistinctName(
                variable0 + '_end',
                NameType.VARIABLE,
            );
            code += 'var ' + endVar + ' = ' + argument1 + ';\n';
        }
        // Determine loop direction at start, in case one of the bounds
        // changes during loop execution.
        const incVar = generator.nameDB_!.getDistinctName(
            variable0 + '_inc',
            NameType.VARIABLE,
        );
        code += 'var ' + incVar + ' = ';
        if (stringUtils.isNumber(increment)) {
            code += Math.abs(Number(increment)) + ';\n';
        } else {
            code += 'Math.abs(' + increment + ');\n';
        }
        code += 'if (' + startVar + ' > ' + endVar + ') {\n';
        code += generator.INDENT + incVar + ' = -' + incVar + ';\n';
        code += '}\n';
        code +=
            'for (' +
            variable0 +
            ' = ' +
            startVar +
            '; ' +
            incVar +
            ' >= 0 ? ' +
            variable0 +
            ' <= ' +
            endVar +
            ' : ' +
            variable0 +
            ' >= ' +
            endVar +
            '; ' +
            variable0 +
            ' += ' +
            incVar +
            ') {\n' +
            branch +
            '}\n';
    }
    return code;
}

export function controls_forEach(block: Block, generator: JavascriptGenerator) {
    // For each loop.
    const variable0 = generator.getVariableName(block.getFieldValue('VAR'));
    const argument0 =
        generator.valueToCode(block, 'LIST', Order.ASSIGNMENT) || '[]';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block);
    let code = '';
    // Cache non-trivial values to variables to prevent repeated look-ups.
    let listVar = argument0;
    if (!argument0.match(/^\w+$/)) {
        listVar = generator.nameDB_!.getDistinctName(
            variable0 + '_list',
            NameType.VARIABLE,
        );
        code += 'var ' + listVar + ' = ' + argument0 + ';\n';
    }
    const indexVar = generator.nameDB_!.getDistinctName(
        variable0 + '_index',
        NameType.VARIABLE,
    );
    branch =
        generator.INDENT +
        variable0 +
        ' = ' +
        listVar +
        '[' +
        indexVar +
        '];\n' +
        branch;
    code += 'for (var ' + indexVar + ' in ' + listVar + ') {\n' + branch + '}\n';
    return code;
}

export function controls_flow_statements(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Flow statements: continue, break.
    let xfix = '';
    if (generator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        xfix += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    if (generator.STATEMENT_SUFFIX) {
        // Inject any statement suffix here since the regular one at the end
        // will not get executed if the break/continue is triggered.
        xfix += generator.injectId(generator.STATEMENT_SUFFIX, block);
    }
    if (generator.STATEMENT_PREFIX) {
        const loop = (block as any).getSurroundLoop();
        if (loop && !loop.suppressPrefixSuffix) {
            // Inject loop's statement prefix here since the regular one at the end
            // of the loop will not get executed if 'continue' is triggered.
            // In the case of 'break', a prefix is needed due to the loop's suffix.
            xfix += generator.injectId(generator.STATEMENT_PREFIX, loop);
        }
    }
    switch (block.getFieldValue('FLOW')) {
        case 'BREAK':
            return xfix + 'break;\n';
        case 'CONTINUE':
            return xfix + 'continue;\n';
    }
    throw Error('Unknown flow statement.');
}


export function math_number(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Numeric value.
    const number = Number(block.getFieldValue('NUM'));
    const order = number >= 0 ? Order.ATOMIC : Order.UNARY_NEGATION;
    return [String(number), order];
}

export function math_arithmetic(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Basic arithmetic operators, and power.
    const OPERATORS: Record<string, [string | null, Order]> = {
        'ADD': [' + ', Order.ADDITION],
        'MINUS': [' - ', Order.SUBTRACTION],
        'MULTIPLY': [' * ', Order.MULTIPLICATION],
        'DIVIDE': [' / ', Order.DIVISION],
        'POWER': [null, Order.NONE], // Handle power separately.
    };
    type OperatorOption = keyof typeof OPERATORS;
    const tuple = OPERATORS[block.getFieldValue('OP') as OperatorOption];
    const operator = tuple[0];
    const order = tuple[1];
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    let code;
    // Power in JavaScript requires a special case since it has no operator.
    if (!operator) {
        code = 'Math.pow(' + argument0 + ', ' + argument1 + ')';
        return [code, Order.FUNCTION_CALL];
    }
    code = argument0 + operator + argument1;
    return [code, order];
}

export function math_single(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Math operators with single operand.
    const operator = block.getFieldValue('OP');
    let code;
    let arg;
    if (operator === 'NEG') {
        // Negation is a special case given its different operator precedence.
        arg = generator.valueToCode(block, 'NUM', Order.UNARY_NEGATION) || '0';
        if (arg[0] === '-') {
            // --3 is not legal in JS.
            arg = ' ' + arg;
        }
        code = '-' + arg;
        return [code, Order.UNARY_NEGATION];
    }
    if (operator === 'SIN' || operator === 'COS' || operator === 'TAN') {
        arg = generator.valueToCode(block, 'NUM', Order.DIVISION) || '0';
    } else {
        arg = generator.valueToCode(block, 'NUM', Order.NONE) || '0';
    }
    // First, handle cases which generate values that don't need parentheses
    // wrapping the code.
    switch (operator) {
        case 'ABS':
            code = 'Math.abs(' + arg + ')';
            break;
        case 'ROOT':
            code = 'Math.sqrt(' + arg + ')';
            break;
        case 'LN':
            code = 'Math.log(' + arg + ')';
            break;
        case 'EXP':
            code = 'Math.exp(' + arg + ')';
            break;
        case 'POW10':
            code = 'Math.pow(10,' + arg + ')';
            break;
        case 'ROUND':
            code = 'Math.round(' + arg + ')';
            break;
        case 'ROUNDUP':
            code = 'Math.ceil(' + arg + ')';
            break;
        case 'ROUNDDOWN':
            code = 'Math.floor(' + arg + ')';
            break;
        case 'SIN':
            code = 'Math.sin(' + arg + ' / 180 * Math.PI)';
            break;
        case 'COS':
            code = 'Math.cos(' + arg + ' / 180 * Math.PI)';
            break;
        case 'TAN':
            code = 'Math.tan(' + arg + ' / 180 * Math.PI)';
            break;
    }
    if (code) {
        return [code, Order.FUNCTION_CALL];
    }
    // Second, handle cases which generate values that may need parentheses
    // wrapping the code.
    switch (operator) {
        case 'LOG10':
            code = 'Math.log(' + arg + ') / Math.log(10)';
            break;
        case 'ASIN':
            code = 'Math.asin(' + arg + ') / Math.PI * 180';
            break;
        case 'ACOS':
            code = 'Math.acos(' + arg + ') / Math.PI * 180';
            break;
        case 'ATAN':
            code = 'Math.atan(' + arg + ') / Math.PI * 180';
            break;
        default:
            throw Error('Unknown math operator: ' + operator);
    }
    return [code, Order.DIVISION];
}

export function math_constant(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Constants: PI, E, the Golden Ratio, sqrt(2), 1/sqrt(2), INFINITY.
    const CONSTANTS: Record<string, [string, Order]> = {
        'PI': ['Math.PI', Order.MEMBER],
        'E': ['Math.E', Order.MEMBER],
        'GOLDEN_RATIO': ['(1 + Math.sqrt(5)) / 2', Order.DIVISION],
        'SQRT2': ['Math.SQRT2', Order.MEMBER],
        'SQRT1_2': ['Math.SQRT1_2', Order.MEMBER],
        'INFINITY': ['Infinity', Order.ATOMIC],
    };
    type ConstantOption = keyof typeof CONSTANTS;
    return CONSTANTS[block.getFieldValue('CONSTANT') as ConstantOption];
}

export function math_number_property(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Check if a number is even, odd, prime, whole, positive, or negative
    // or if it is divisible by certain number. Returns true or false.
    const PROPERTIES: Record<string, [string | null, Order, Order]> = {
        'EVEN': [' % 2 === 0', Order.MODULUS, Order.EQUALITY],
        'ODD': [' % 2 === 1', Order.MODULUS, Order.EQUALITY],
        'WHOLE': [' % 1 === 0', Order.MODULUS, Order.EQUALITY],
        'POSITIVE': [' > 0', Order.RELATIONAL, Order.RELATIONAL],
        'NEGATIVE': [' < 0', Order.RELATIONAL, Order.RELATIONAL],
        'DIVISIBLE_BY': [null, Order.MODULUS, Order.EQUALITY],
        'PRIME': [null, Order.NONE, Order.FUNCTION_CALL],
    };
    type PropertyOption = keyof typeof PROPERTIES;
    const dropdownProperty = block.getFieldValue('PROPERTY') as PropertyOption;
    const [suffix, inputOrder, outputOrder] = PROPERTIES[dropdownProperty];
    const numberToCheck =
        generator.valueToCode(block, 'NUMBER_TO_CHECK', inputOrder) || '0';
    let code;
    if (dropdownProperty === 'PRIME') {
        // Prime is a special case as it is not a one-liner test.
        const functionName = generator.provideFunction_(
            'mathIsPrime',
            `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(n) {
    // https://en.wikipedia.org/wiki/Primality_test#Naive_methods
    if (n == 2 || n == 3) {
      return true;
    }
    // False if n is NaN, negative, is 1, or not whole.
    // And false if n is divisible by 2 or 3.
    if (isNaN(n) || n <= 1 || n % 1 !== 0 || n % 2 === 0 || n % 3 === 0) {
      return false;
    }
    // Check all the numbers of form 6k +/- 1, up to sqrt(n).
    for (var x = 6; x <= Math.sqrt(n) + 1; x += 6) {
      if (n % (x - 1) === 0 || n % (x + 1) === 0) {
        return false;
      }
    }
    return true;
  }
  `,
        );
        code = functionName + '(' + numberToCheck + ')';
    } else if (dropdownProperty === 'DIVISIBLE_BY') {
        const divisor =
            generator.valueToCode(block, 'DIVISOR', Order.MODULUS) || '0';
        code = numberToCheck + ' % ' + divisor + ' === 0';
    } else {
        code = numberToCheck + suffix;
    }
    return [code, outputOrder];
}

export function math_change(block: Block, generator: JavascriptGenerator) {
    // Add to a variable in place.
    const argument0 =
        generator.valueToCode(block, 'DELTA', Order.ADDITION) || '0';
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    return (
        varName +
        ' = (typeof ' +
        varName +
        " === 'number' ? " +
        varName +
        ' : 0) + ' +
        argument0 +
        ';\n'
    );
}

// Rounding functions have a single operand.
export const math_round = math_single;
// Trigonometry functions have a single operand.
export const math_trig = math_single;

export function math_on_list(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Math functions for lists.
    const func = block.getFieldValue('OP');
    let list;
    let code;
    switch (func) {
        case 'SUM':
            list = generator.valueToCode(block, 'LIST', Order.MEMBER) || '[]';
            code = list + '.reduce(function(x, y) {return x + y;}, 0)';
            break;
        case 'MIN':
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = 'Math.min.apply(null, ' + list + ')';
            break;
        case 'MAX':
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = 'Math.max.apply(null, ' + list + ')';
            break;
        case 'AVERAGE': {
            // mathMean([null,null,1,3]) === 2.0.
            const functionName = generator.provideFunction_(
                'mathMean',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(myList) {
    return myList.reduce(function(x, y) {return x + y;}, 0) / myList.length;
  }
  `,
            );
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = functionName + '(' + list + ')';
            break;
        }
        case 'MEDIAN': {
            // mathMedian([null,null,1,3]) === 2.0.
            const functionName = generator.provideFunction_(
                'mathMedian',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(myList) {
    var localList = myList.filter(function (x) {return typeof x === 'number';});
    if (!localList.length) return null;
    localList.sort(function(a, b) {return b - a;});
    if (localList.length % 2 === 0) {
      return (localList[localList.length / 2 - 1] + localList[localList.length / 2]) / 2;
    } else {
      return localList[(localList.length - 1) / 2];
    }
  }
  `,
            );
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = functionName + '(' + list + ')';
            break;
        }
        case 'MODE': {
            // As a list of numbers can contain more than one mode,
            // the returned result is provided as an array.
            // Mode of [3, 'x', 'x', 1, 1, 2, '3'] -> ['x', 1].
            const functionName = generator.provideFunction_(
                'mathModes',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(values) {
    var modes = [];
    var counts = [];
    var maxCount = 0;
    for (var i = 0; i < values.length; i++) {
      var value = values[i];
      var found = false;
      var thisCount;
      for (var j = 0; j < counts.length; j++) {
        if (counts[j][0] === value) {
          thisCount = ++counts[j][1];
          found = true;
          break;
        }
      }
      if (!found) {
        counts.push([value, 1]);
        thisCount = 1;
      }
      maxCount = Math.max(thisCount, maxCount);
    }
    for (var j = 0; j < counts.length; j++) {
      if (counts[j][1] === maxCount) {
        modes.push(counts[j][0]);
      }
    }
    return modes;
  }
  `,
            );
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = functionName + '(' + list + ')';
            break;
        }
        case 'STD_DEV': {
            const functionName = generator.provideFunction_(
                'mathStandardDeviation',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(numbers) {
    var n = numbers.length;
    if (!n) return null;
    var mean = numbers.reduce(function(x, y) {return x + y;}) / n;
    var variance = 0;
    for (var j = 0; j < n; j++) {
      variance += Math.pow(numbers[j] - mean, 2);
    }
    variance /= n;
    return Math.sqrt(variance);
  }
  `,
            );
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = functionName + '(' + list + ')';
            break;
        }
        case 'RANDOM': {
            const functionName = generator.provideFunction_(
                'mathRandomList',
                `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(list) {
    var x = Math.floor(Math.random() * list.length);
    return list[x];
  }
  `,
            );
            list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
            code = functionName + '(' + list + ')';
            break;
        }
        default:
            throw Error('Unknown operator: ' + func);
    }
    return [code, Order.FUNCTION_CALL];
}

export function math_modulo(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Remainder computation.
    const argument0 =
        generator.valueToCode(block, 'DIVIDEND', Order.MODULUS) || '0';
    const argument1 =
        generator.valueToCode(block, 'DIVISOR', Order.MODULUS) || '0';
    const code = argument0 + ' % ' + argument1;
    return [code, Order.MODULUS];
}

export function math_constrain(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Constrain a number between two limits.
    const argument0 = generator.valueToCode(block, 'VALUE', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'LOW', Order.NONE) || '0';
    const argument2 =
        generator.valueToCode(block, 'HIGH', Order.NONE) || 'Infinity';
    const code =
        'Math.min(Math.max(' +
        argument0 +
        ', ' +
        argument1 +
        '), ' +
        argument2 +
        ')';
    return [code, Order.FUNCTION_CALL];
}

export function math_random_int(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Random integer between [X] and [Y].
    const argument0 = generator.valueToCode(block, 'FROM', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'TO', Order.NONE) || '0';
    const functionName = generator.provideFunction_(
        'mathRandomInt',
        `
  function ${generator.FUNCTION_NAME_PLACEHOLDER_}(a, b) {
    if (a > b) {
      // Swap a and b to ensure a is smaller.
      var c = a;
      a = b;
      b = c;
    }
    return Math.floor(Math.random() * (b - a + 1) + a);
  }
  `,
    );
    const code = functionName + '(' + argument0 + ', ' + argument1 + ')';
    return [code, Order.FUNCTION_CALL];
}

export function math_random_float(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Random fraction between 0 and 1.
    return ['Math.random()', Order.FUNCTION_CALL];
}

export function math_atan2(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Arctangent of point (X, Y) in degrees from -180 to 180.
    const argument0 = generator.valueToCode(block, 'X', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'Y', Order.NONE) || '0';
    return [
        'Math.atan2(' + argument1 + ', ' + argument0 + ') / Math.PI * 180',
        Order.DIVISION,
    ];
}


export function procedures_defreturn(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Define a procedure with a return value.
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    let xfix1 = '';
    if (generator.STATEMENT_PREFIX) {
        xfix1 += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    if (generator.STATEMENT_SUFFIX) {
        xfix1 += generator.injectId(generator.STATEMENT_SUFFIX, block);
    }
    if (xfix1) {
        xfix1 = generator.prefixLines(xfix1, generator.INDENT);
    }
    let loopTrap = '';
    if (generator.INFINITE_LOOP_TRAP) {
        loopTrap = generator.prefixLines(
            generator.injectId(generator.INFINITE_LOOP_TRAP, block),
            generator.INDENT,
        );
    }
    let branch = '';
    if (block.getInput('STACK')) {
        // The 'procedures_defreturn' block might not have a STACK input.
        branch = generator.statementToCode(block, 'STACK');
    }
    let returnValue = '';
    if (block.getInput('RETURN')) {
        // The 'procedures_defnoreturn' block (which shares this code)
        // does not have a RETURN input.
        returnValue = generator.valueToCode(block, 'RETURN', Order.NONE) || '';
    }
    let xfix2 = '';
    if (branch && returnValue) {
        // After executing the function body, revisit this block for the return.
        xfix2 = xfix1;
    }
    if (returnValue) {
        returnValue = generator.INDENT + 'return ' + returnValue + ';\n';
    }
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.getVariableName(variables[i]);
    }
    let code =
        'function ' +
        funcName +
        '(' +
        args.join(', ') +
        ') {\n' +
        xfix1 +
        loopTrap +
        branch +
        xfix2 +
        returnValue +
        '}';
    code = generator.scrub_(block, code);
    // Add % so as not to collide with helper functions in definitions list.
    // TODO(#7600): find better approach than casting to any to override
    // CodeGenerator declaring .definitions protected.
    (generator as any).definitions_['%' + funcName] = code;
    return null;
}

// Defining a procedure without a return value uses the same generator as
// a procedure with a return value.
export const procedures_defnoreturn = procedures_defreturn;

export function procedures_callreturn(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Call a procedure with a return value.
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.valueToCode(block, 'ARG' + i, Order.NONE) || 'null';
    }
    const code = funcName + '(' + args.join(', ') + ')';
    return [code, Order.FUNCTION_CALL];
}

export function procedures_callnoreturn(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Call a procedure with no return value.
    // Generated code is for a function call as a statement is the same as a
    // function call as a value, with the addition of line ending.
    const tuple = generator.forBlock['procedures_callreturn'](
        block,
        generator,
    ) as [string, Order];
    return tuple[0] + ';\n';
}

export function procedures_ifreturn(
    block: Block,
    generator: JavascriptGenerator,
) {
    // Conditionally return value from a procedure.
    const condition =
        generator.valueToCode(block, 'CONDITION', Order.NONE) || 'false';
    let code = 'if (' + condition + ') {\n';
    if (generator.STATEMENT_SUFFIX) {
        // Inject any statement suffix here since the regular one at the end
        // will not get executed if the return is triggered.
        code += generator.prefixLines(
            generator.injectId(generator.STATEMENT_SUFFIX, block),
            generator.INDENT,
        );
    }
    if ((block as any).hasReturnValue_) {
        const value = generator.valueToCode(block, 'VALUE', Order.NONE) || 'null';
        code += generator.INDENT + 'return ' + value + ';\n';
    } else {
        code += generator.INDENT + 'return;\n';
    }
    code += '}\n';
    return code;
}


const strRegExp = /^\s*'([^']|\\')*'\s*$/;

/**
 * Enclose the provided value in 'String(...)' function.
 * Leave string literals alone.
 * @param value Code evaluating to a value.
 * @returns Array containing code evaluating to a string
 *     and the order of the returned code.[string, number]
 */
const forceString = function (value: string): [string, Order] {
    if (strRegExp.test(value)) {
        return [value, Order.ATOMIC];
    }
    return ['String(' + value + ')', Order.FUNCTION_CALL];
};

/**
 * Returns an expression calculating the index into a string.
 * @param stringName Name of the string, used to calculate length.
 * @param where The method of indexing, selected by dropdown in Blockly
 * @param opt_at The optional offset when indexing from start/end.
 * @returns Index expression.
 */

export function text(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Text value.
    const code = generator.quote_(block.getFieldValue('TEXT'));
    return [code, Order.ATOMIC];
}

export function text_join(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Create a string made up of any number of elements of any type.
    const joinBlock = block as any;
    switch (joinBlock.itemCount_) {
        case 0:
            return ["''", Order.ATOMIC];
        case 1: {
            const element =
                generator.valueToCode(joinBlock, 'ADD0', Order.NONE) || "''";
            const codeAndOrder = forceString(element);
            return codeAndOrder;
        }
        case 2: {
            const element0 =
                generator.valueToCode(joinBlock, 'ADD0', Order.NONE) || "''";
            const element1 =
                generator.valueToCode(joinBlock, 'ADD1', Order.NONE) || "''";
            const code = forceString(element0)[0] + ' + ' + forceString(element1)[0];
            return [code, Order.ADDITION];
        }
        default: {
            const elements = new Array(joinBlock.itemCount_);
            for (let i = 0; i < joinBlock.itemCount_; i++) {
                elements[i] =
                    generator.valueToCode(joinBlock, 'ADD' + i, Order.NONE) || "''";
            }
            const code = '[' + elements.join(',') + "].join('')";
            return [code, Order.FUNCTION_CALL];
        }
    }
}

export function text_append(block: Block, generator: JavascriptGenerator) {
    // Append to a variable in place.
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    const value = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    const code = varName + ' += ' + forceString(value)[0] + ';\n';
    return code;
}

export function text_length(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // String or array length.
    const text = generator.valueToCode(block, 'VALUE', Order.MEMBER) || "''";
    return [text + '.length', Order.MEMBER];
}

export function text_isEmpty(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Is the string null or array empty?
    const text = generator.valueToCode(block, 'VALUE', Order.MEMBER) || "''";
    return ['!' + text + '.length', Order.LOGICAL_NOT];
}

export function text_indexOf(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Search the text for a substring.
    const operator =
        block.getFieldValue('END') === 'FIRST' ? 'indexOf' : 'lastIndexOf';
    const substring = generator.valueToCode(block, 'FIND', Order.NONE) || "''";
    const text = generator.valueToCode(block, 'VALUE', Order.MEMBER) || "''";
    const code = text + '.' + operator + '(' + substring + ')';
    // Adjust index if using one-based indices.
    if (block.workspace.options.oneBasedIndex) {
        return [code + ' + 1', Order.ADDITION];
    }
    return [code, Order.FUNCTION_CALL];
}

export function text_charAt(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Get letter at index.
    // Note: Until January 2013 this block did not have the WHERE input.
    const where = block.getFieldValue('WHERE') || 'FROM_START';
    const textOrder = where === 'RANDOM' ? Order.NONE : Order.MEMBER;
    const text = generator.valueToCode(block, 'VALUE', textOrder) || "''";
    switch (where) {
        case 'FIRST': {
            const code = text + '.charAt(0)';
            return [code, Order.FUNCTION_CALL];
        }
        case 'LAST': {
            const code = text + '.slice(-1)';
            return [code, Order.FUNCTION_CALL];
        }
        case 'FROM_START': {
            const at = generator.getAdjusted(block, 'AT');
            // Adjust index if using one-based indices.
            const code = text + '.charAt(' + at + ')';
            return [code, Order.FUNCTION_CALL];
        }
        case 'FROM_END': {
            const at = generator.getAdjusted(block, 'AT', 1, true);
            const code = text + '.slice(' + at + ').charAt(0)';
            return [code, Order.FUNCTION_CALL];
        }
        case 'RANDOM': {
            const functionName = generator.provideFunction_(
                'textRandomLetter',
                `
function ${generator.FUNCTION_NAME_PLACEHOLDER_}(text) {
  var x = Math.floor(Math.random() * text.length);
  return text[x];
}
`,
            );
            const code = functionName + '(' + text + ')';
            return [code, Order.FUNCTION_CALL];
        }
    }
    throw Error('Unhandled option (text_charAt).');
}

export function text_getSubstring(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Dictionary of WHEREn field choices and their CamelCase equivalents. */
    const wherePascalCase = {
        'FIRST': 'First',
        'LAST': 'Last',
        'FROM_START': 'FromStart',
        'FROM_END': 'FromEnd',
    };
    type WhereOption = keyof typeof wherePascalCase;
    // Get substring.
    const where1 = block.getFieldValue('WHERE1') as WhereOption;
    const where2 = block.getFieldValue('WHERE2') as WhereOption;
    const requiresLengthCall =
        where1 !== 'FROM_END' &&
        where1 !== 'LAST' &&
        where2 !== 'FROM_END' &&
        where2 !== 'LAST';
    const textOrder = requiresLengthCall ? Order.MEMBER : Order.NONE;
    const text = generator.valueToCode(block, 'STRING', textOrder) || "''";
    let code;
    if (where1 === 'FIRST' && where2 === 'LAST') {
        code = text;
        return [code, Order.NONE];
    } else if (text.match(/^'?\w+'?$/) || requiresLengthCall) {
        // If the text is a variable or literal or doesn't require a call for
        // length, don't generate a helper function.
        let at1;
        switch (where1) {
            case 'FROM_START':
                at1 = generator.getAdjusted(block, 'AT1');
                break;
            case 'FROM_END':
                at1 = generator.getAdjusted(block, 'AT1', 1, false, Order.SUBTRACTION);
                at1 = text + '.length - ' + at1;
                break;
            case 'FIRST':
                at1 = '0';
                break;
            default:
                throw Error('Unhandled option (text_getSubstring).');
        }
        let at2;
        switch (where2) {
            case 'FROM_START':
                at2 = generator.getAdjusted(block, 'AT2', 1);
                break;
            case 'FROM_END':
                at2 = generator.getAdjusted(block, 'AT2', 0, false, Order.SUBTRACTION);
                at2 = text + '.length - ' + at2;
                break;
            case 'LAST':
                at2 = text + '.length';
                break;
            default:
                throw Error('Unhandled option (text_getSubstring).');
        }
        code = text + '.slice(' + at1 + ', ' + at2 + ')';
    } else {
        const at1 = generator.getAdjusted(block, 'AT1');
        const at2 = generator.getAdjusted(block, 'AT2');
        // The value for 'FROM_END' and'FROM_START' depends on `at` so
        // we add it as a parameter.
        const at1Param =
            where1 === 'FROM_END' || where1 === 'FROM_START' ? ', at1' : '';
        const at2Param =
            where2 === 'FROM_END' || where2 === 'FROM_START' ? ', at2' : '';
        const functionName = generator.provideFunction_(
            'subsequence' + wherePascalCase[where1] + wherePascalCase[where2],
            `
function ${generator.FUNCTION_NAME_PLACEHOLDER_
            }(sequence${at1Param}${at2Param}) {
  var start = ${getSubstringIndex('sequence', where1, 'at1')};
  var end = ${getSubstringIndex('sequence', where2, 'at2')} + 1;
  return sequence.slice(start, end);
}
`,
        );
        code =
            functionName +
            '(' +
            text +
            // The value for 'FROM_END' and 'FROM_START' depends on `at` so we
            // pass it.
            (where1 === 'FROM_END' || where1 === 'FROM_START' ? ', ' + at1 : '') +
            (where2 === 'FROM_END' || where2 === 'FROM_START' ? ', ' + at2 : '') +
            ')';
    }
    return [code, Order.FUNCTION_CALL];
}

export function text_changeCase(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Change capitalization.
    const OPERATORS = {
        'UPPERCASE': '.toUpperCase()',
        'LOWERCASE': '.toLowerCase()',
        'TITLECASE': null,
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('CASE') as OperatorOption];
    const textOrder = operator ? Order.MEMBER : Order.NONE;
    const text = generator.valueToCode(block, 'TEXT', textOrder) || "''";
    let code;
    if (operator) {
        // Upper and lower case are functions built into generator.
        code = text + operator;
    } else {
        // Title case is not a native JavaScript function.  Define one.
        const functionName = generator.provideFunction_(
            'textToTitleCase',
            `
function ${generator.FUNCTION_NAME_PLACEHOLDER_}(str) {
  return str.replace(/\\S+/g,
      function(txt) {return txt[0].toUpperCase() + txt.substring(1).toLowerCase();});
}
`,
        );
        code = functionName + '(' + text + ')';
    }
    return [code, Order.FUNCTION_CALL];
}

export function text_trim(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Trim spaces.
    const OPERATORS = {
        'LEFT': ".replace(/^[\\s\\xa0]+/, '')",
        'RIGHT': ".replace(/[\\s\\xa0]+$/, '')",
        'BOTH': '.trim()',
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('MODE') as OperatorOption];
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    return [text + operator, Order.FUNCTION_CALL];
}

export function text_print(block: Block, generator: JavascriptGenerator) {
    // Print statement.
    const msg = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    return 'window.alert(' + msg + ');\n';
}

export function text_prompt_ext(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Prompt function.
    let msg;
    if (block.getField('TEXT')) {
        // Internal message.
        msg = generator.quote_(block.getFieldValue('TEXT'));
    } else {
        // External message.
        msg = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    }
    let code = 'window.prompt(' + msg + ')';
    const toNumber = block.getFieldValue('TYPE') === 'NUMBER';
    if (toNumber) {
        code = 'Number(' + code + ')';
    }
    return [code, Order.FUNCTION_CALL];
}

export const text_prompt = text_prompt_ext;

export function text_count(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    const sub = generator.valueToCode(block, 'SUB', Order.NONE) || "''";
    const functionName = generator.provideFunction_(
        'textCount',
        `
function ${generator.FUNCTION_NAME_PLACEHOLDER_}(haystack, needle) {
  if (needle.length === 0) {
    return haystack.length + 1;
  } else {
    return haystack.split(needle).length - 1;
  }
}
`,
    );
    const code = functionName + '(' + text + ', ' + sub + ')';
    return [code, Order.FUNCTION_CALL];
}

export function text_replace(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    const from = generator.valueToCode(block, 'FROM', Order.NONE) || "''";
    const to = generator.valueToCode(block, 'TO', Order.NONE) || "''";
    // The regex escaping code below is taken from the implementation of
    // goog.string.regExpEscape.
    const functionName = generator.provideFunction_(
        'textReplace',
        `
function ${generator.FUNCTION_NAME_PLACEHOLDER_}(haystack, needle, replacement) {
  needle = needle.replace(/([-()\\[\\]{}+?*.$\\^|,:#<!\\\\])/g, '\\\\$1')
                 .replace(/\\x08/g, '\\\\x08');
  return haystack.replace(new RegExp(needle, 'g'), replacement);
}
`,
    );
    const code = functionName + '(' + text + ', ' + from + ', ' + to + ')';
    return [code, Order.FUNCTION_CALL];
}

export function text_reverse(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const code = text + ".split('').reverse().join('')";
    return [code, Order.FUNCTION_CALL];
}


export function variables_get(
    block: Block,
    generator: JavascriptGenerator,
): [string, Order] {
    // Variable getter.
    const code = generator.getVariableName(block.getFieldValue('VAR'));
    return [code, Order.ATOMIC];
}

export function variables_set(block: Block, generator: JavascriptGenerator) {
    // Variable setter.
    const argument0 =
        generator.valueToCode(block, 'VALUE', Order.ASSIGNMENT) || '0';
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    return varName + ' = ' + argument0 + ';\n';
}