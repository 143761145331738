import { Button, Card, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, getRandomValueForEducobotColors } from '../../context/SettingsContext'
import { BackendConfigs } from '../../config.environment'
import { UserContext } from '../../context/UserContext'

type Props = {}

function Dashboard({ }: Props) {
    const { user } = useContext(UserContext)
    const [statistics, setStatistics] = useState({
        "workspaceCount": 0,
        "modelsCount": 0
    })

    async function getStatistics() {
        if (user.isLogin) {

            try {
                const response = await fetch(`${BackendConfigs.url}/get-dashboard-statistics`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id
                    })
                })
                if (response.status === 200) {
                    const result = await response.json()
                    setStatistics(
                        result.response
                    )
                }
            } catch (error) {
                console.log(error);

            }
        }
    }



    useEffect(() => {
        getStatistics()
    }, [user])
    return (
        <div>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                minHeight: "80vh",
                margin: "20px 0",
                gap: "5px",
            }}>


                <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} md={12}>
                        <div style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                            gap: "10px",
                            padding: "10px",
                        }}>
                            <CountComponent title="Workspaces" count={statistics.workspaceCount} color={eduCOBOTColorScheme.green} />
                            <CountComponent title="Models" count={statistics.modelsCount} color={eduCOBOTColorScheme.gray} />
                        </div>
                    </Grid>
                </Grid>

            </div>




        </div>
    )
}

export default Dashboard






export function CountComponent({
    color,
    count,
    title
}: {
    count: number,
    color: string,
    title: string
}) {
    return (
        <Card style={{
            width: "250px",
            height: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "bolder",
            flexDirection: "column",
            background: color,
            borderRadius: "20px",
        }}
            sx={{
                ":hover": {
                    background: "#f3f3f3"
                }
            }}
        >
            <h3 style={{
                fontSize: "53px",
                color: eduCOBOTColorScheme.white
            }}>{count}</h3>
            <span style={{
                fontSize: "12px",
                color: eduCOBOTColorScheme.white
            }}>
                {title}
            </span>
        </Card>
    )
}
