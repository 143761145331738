import React, { useContext, useState } from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardTopbar from './DashboardTopBar'
import { SettingContext } from '../../../context/SettingsContext'
import { SwippleDrawer } from '../../../components/drawers'
type Props = {
    children?: React.ReactNode,
    title?: string
}

function DashboardLayout(props: Props) {
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    const [isNavBarOpen, setIsNavBarOpen] = useState(settings.screen === "desktop" ? true : false)
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: settings.screen === "mobile" ? "1fr" : "2fr 10fr",
                height: "100vh",
            }}
        >
            {
                settings.screen === "mobile" ?
                    <SwippleDrawer
                        isOpen={isNavBarOpen}
                        onClose={() => setIsNavBarOpen(false)}
                    >
                        <DashboardNavbar />
                    </SwippleDrawer>
                    :
                    <DashboardNavbar />
            }
            <div style={{
                height: "100vh",
                overflow: "auto",
                padding: "0 10px",
                position:"relative"
            }}>
                <div style={{
                    position: "absolute",
                    width:"97%",
                    height:"100%"
                }}>
                    <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/innovator-assets/Asset+1.svg" style={{
                        width:"30%",
                        opacity: 0.2,
                        position:"absolute",
                        bottom:50,
                        left:"max(150px,5%)"
                    }} alt="" />
                    <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/innovator-assets/Asset+2.svg" style={{
                        width:"20%",
                        opacity: 0.2,
                        position:"absolute",
                        right:"max(30px,5%)",
                        top:"max(300px,5%)"
                    }} alt="" />
                </div>
                <DashboardTopbar title={props.title as string} toggleNavBarOpen={() => {
                    setIsNavBarOpen(!isNavBarOpen)
                }} />
                <div style={{
                    height: "95vh",
                    overflow: "auto",
                    padding: "10px",

                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout