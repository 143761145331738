import React from 'react'

type Props = {}

function Footer({}: Props) {
  return (
    <div>
      
    </div>
  )
}

export default Footer