import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as tmImage from '@teachablemachine/image';
import GraphComponent from '../../../../components/graph';
import { routes } from '../../../../routes/routes';
import { ModelType } from '../../../../types/Database';
import { UserContext } from '../../../../context/UserContext';
import { BackendConfigs } from '../../../../config.environment';
import { SettingContext } from '../../../../context/SettingsContext';
import { Button } from '@mui/material';

type Props = {}


export function getOutput(preview: any) {
    if (preview && preview.forEach) {
        let maxPercentage = 0
        let output = ""
        preview.forEach((previewItem: any) => {
            if (previewItem.probability > maxPercentage) {
                maxPercentage = previewItem.probability
                output = previewItem.className
            }
        })
        return output
    }
}

const size = window.innerHeight
const interval = 100
const preview = true
export default function ModellingWorkSpacePage({ }: Props) {
    const { modelId } = useParams()
    const { user, changeUser, } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    const navigate = useNavigate()
    const [model_url, setUrl] = useState<string | null>()
    const [prediction, setPrediction] = useState<any>(null);
    const previewRef = React.useRef<HTMLDivElement>();
    const requestRef = React.useRef<number>();
    const intervalRef = React.useRef<any>();

    const [modelData, setModelData] = React.useState<ModelType>()


    async function getModel() {
        handleGlobalLoading(true)
        if (user.isLogin && modelId) {
            console.log({
                userId: user.userData?.id,
                modelId: modelId,
            });

            try {
                const response = await fetch(`${BackendConfigs.url}/get-all-model-by-user-id-and-mode-id-api`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        modelId: modelId,
                    })
                })
                if (response.status === 200) {
                    const data = await response.json()
                    console.log(data);
                    if (data.success) {
                        !modelData && setModelData(data.response)
                    } else {
                        navigate(routes.DASHBOARD.MODELS)
                    }
                } else {
                    navigate(routes.DASHBOARD.MODELS)
                }
            } catch (error) {
                navigate(routes.DASHBOARD.MODELS)
                console.log(error);
            }
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        if (user.isLogin && modelId) {
            getModel()
        }
    }, [modelId, user])
    async function init() {
        handleGlobalLoading(true)
        if (!!modelData) {
            const modelURL = modelData.fileUrls.modelJson;
            const metadataURL = modelData.fileUrls.modelMetaData;
            const model = await tmImage.load(modelURL, metadataURL);
            console.log(model);

            const flip = true;
            const webcam = new tmImage.Webcam(size * 1.5, size, flip);
            await webcam.setup({
                aspectRatio: 1.5,
            });
            try {
                await webcam.play();
            }
            catch (e) {
                console.log(e);
            }
            if (interval === null) {

                requestRef.current = window.requestAnimationFrame(loop);
            } else {
                intervalRef.current = setTimeout(loop, interval) as any;
            }
            if (preview) {
                previewRef.current && previewRef.current.replaceChildren(webcam.canvas);
            }
            async function loop() {
                if (webcam === null) { } else {
                    webcam.update();
                    await predict();
                }
                if (interval === null) {
                    requestRef.current = window.requestAnimationFrame(loop);
                } else {
                    intervalRef.current = setTimeout(loop, interval);
                }
            }
            async function predict() {
                const prediction = await model.predict(webcam.canvas);
                setPrediction(prediction);
                console.log(prediction);
            }
        }
        setTimeout(() => {
            handleGlobalLoading(false)
        }, 2000)
    }

    useEffect(() => {
        if (!!modelData) {
            init();
        }
        return () => {
            // if (interval === null) {
                cancelAnimationFrame(requestRef.current as number);
            // } else {
                clearTimeout(intervalRef.current);
            // }
            (document.querySelector('#webcam-container') as HTMLDivElement) && (document.querySelector('#webcam-container') as HTMLDivElement).firstChild?.remove();
        }
    }, [modelData]);
    useEffect(() => {
        return () => {
            console.log("unmounting",intervalRef.current);
            
            clearTimeout(intervalRef.current);
            cancelAnimationFrame(requestRef.current as number);
        }
    },[])
    let label = [] as any[];
    function getCameraComponent() {
        return React.createElement("div", null, label, React.createElement("div", {
            id: "webcam-container",
            ref: previewRef,
        }));
    }
    return <div style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: 'column',
    }}>
        <div style={{
            border: "1px solid gray",
            borderRadius: "10px",
            overflow: "hidden",
            position: "relative",
        }}>
            {
                preview && getCameraComponent()
            }
            {
                !settings.globalLoading &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }}>
                    <Button size='small' variant='outlined' sx={{
                        margin: "10px",
                        textTransform: "none",
                        backgroundColor: "white",
                    }}>{getOutput(prediction)}</Button>
                </div>
            }
            <div style={{
                position: "absolute",
                bottom: 0,
                right: 0,
            }}>
                {
                    !!prediction && !settings.globalLoading && <GraphComponent height={200} data={
                        prediction.map((value: any) => { return parseInt((value.probability * 100).toFixed(2)) })
                    }
                        labels={
                            prediction.map((value: any) => { return value.className })
                        }
                        yLabel='% probability'
                    />
                }
            </div>
        </div>
    </div>

}