import React from 'react'
import ReactApexChart from 'react-apexcharts'

type Props = {
    data: [],
    labels: []
    yLabel: string,
    height?: number,
}

export default function GraphComponent({
    data,
    labels,
    yLabel,
    height=350
}: Props) {
    return (
        <div>
            <ReactApexChart type="bar" width={"100%"} height={height} series={[{
                name: 'Model Predictions',
                data: data
            }]}
                options={{
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    xaxis: {
                        categories: labels
                    },
                    yaxis: {
                        title: {
                            text: yLabel
                        }
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val + " percentage"
                            }
                        }
                    }
                }}
            /></div>
    )
}