import React from 'react'
import Drawer from '@mui/material/Drawer';

type Props = {
    children?: React.ReactNode,
    isOpen: boolean,
    onClose: () => void,
    drawerPosition?:'left' | 'right' | 'top' | 'bottom'
}

function SwippleDrawer({
    children,
    isOpen,
    onClose,
    drawerPosition="left"
}: Props) {
    return (
        <div style={{
            zIndex: 999999999
        }}>
            <Drawer open={isOpen} onClose={onClose} anchor={drawerPosition}>
                {children}
            </Drawer>
        </div>
    )
}

export default SwippleDrawer