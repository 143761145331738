export const images: { [key: string]: string } = {
    "Hand": "https://cdn-icons-png.flaticon.com/512/827/827980.png",
    "Phone": "https://cdn-icons-png.flaticon.com/512/545/545245.png",
    "Show Digit 0": "https://cdn-icons-png.flaticon.com/512/6912/6912861.png",
    "Show Digit 1": "https://cdn-icons-png.flaticon.com/512/6335/6335489.png",
    "Show Digit 2": "https://cdn-icons-png.flaticon.com/512/6335/6335569.png",
    "Show Digit 3": "https://cdn-icons-png.flaticon.com/512/3840/3840739.png",
    "Show Digit 4": "https://cdn-icons-png.flaticon.com/512/3840/3840753.png",
    "Show Digit 5": "https://cdn-icons-png.flaticon.com/512/3840/3840754.png",
    "Show Digit 6": "https://cdn-icons-png.flaticon.com/512/11516/11516933.png",
    "Show Digit 7": "https://cdn-icons-png.flaticon.com/512/6913/6913794.png",
    "Show Digit 8": "https://cdn-icons-png.flaticon.com/512/11169/11169125.png",
    "Show Digit 9": "https://cdn-icons-png.flaticon.com/512/3840/3840773.png",









    "7 Wonders on Map": "https://cdn.thecollector.com/wp-content/uploads/2024/06/map-new-seven-wonders-of-the-world-small.jpg",
    "The Taj Mahal, India": "https://cdn.thecollector.com/wp-content/uploads/2021/12/the-taj-mahal-architectural-digest.jpg",

    "The Colosseum, Rome, Italy": "https://cdn.thecollector.com/wp-content/uploads/2021/12/colosseum-world-wonder-national-geographic.jpg",
    "The Great Wall of China": "https://cdn.thecollector.com/wp-content/uploads/2021/12/great-wall-china-national-geographic.jpg",

    "Christ the Redeemer, Brazil": "https://cdn.thecollector.com/wp-content/uploads/2021/12/christ-the-redeemer-statue-rio-1-scaled.jpg",
    "Machu Picchu, Peru": "https://cdn.thecollector.com/wp-content/uploads/2021/12/machu-picchu-world-wonder.jpg",
    "Chichén Itzá, Mexico": "https://cdn.thecollector.com/wp-content/uploads/2021/12/chichen-itza-image-1-1.jpg",
    "Petra, Jordan": "https://cdn.thecollector.com/wp-content/uploads/2021/12/petra-jordan-world-wonder-scaled-1.jpg",








    "Solar System": "https://live.staticflickr.com/3085/3143746144_99a980221a_k.jpg",


    "Sun": "https://spaceplace.nasa.gov/gallery-sun/en/solar-flare.en.jpg",
    "Mercury Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/pia15162-mercury-basins-messenger-16x9-1.jpg?w=3072&format=webp",
    "venus Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/venus-mariner-10-pia23791-fig2-16x9-1.jpg?w=3072&format=webp",
    "Earth": "https://science.nasa.gov/wp-content/uploads/2024/03/blue-marble-apollo-17-16x9-1.jpg?w=3072&format=webp",
    "Mars Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/mars-full-globe-16x9-1.jpg?w=3072&format=webp",
    "Jupiter Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/jupiter-marble-pia22946-16x9-1.jpg?w=3072&format=webp",
    "Saturn Planet": "https://science.nasa.gov/wp-content/uploads/2023/05/saturn-farewell-pia21345-sse-banner-1920x640-1.jpg?w=3072&format=webp",
    "Uranus Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/uranus-pia18182-16x9-1.jpg?w=3072&format=webp",
    "Neptune Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/pia01492-neptune-full-disk-16x9-1.jpg?w=3072&format=webp",




    "Ceres Dwarf Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/ceres-dawn-pia19562-16x9-1.jpg?w=3072&format=webp",
    "Pluto Dwarf Planet": "https://science.nasa.gov/wp-content/uploads/2024/03/pluto-new-horizons-pia20291-16x9-1.jpg?w=3072&format=webp",


}