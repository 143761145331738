import { createTheme } from "@mui/material/styles";
import { AppTheme } from "..";

const { palette } = createTheme();

export const theme: AppTheme = {
    dark: {
        palette: {
            mode: "dark",
            upvote: palette.augmentColor({
                color: {
                    main: "#66bb6a",
                    contrastText: "rgba(0,0,0,0.87)",
                },
            }),
            downvote: palette.augmentColor({
                color: {
                    main: "#f44336",
                    contrastText: "#637381",
                },
            }),
            containerPrimary: palette.augmentColor({
                color: {
                    main: "#161C24",
                    contrastText: "white",
                },
            }),
            containerSecondary: palette.augmentColor({
                color: {
                    main: "#161C24",
                    contrastText: "white",
                },
            }),
        },
    },
    light: {
        palette: {
            mode: "light",
            upvote: palette.augmentColor({
                color: {
                    main: "#2e7d32",
                    contrastText: "#32009a",
                },
            }),
            downvote: palette.augmentColor({
                color: {
                    main: "#d32f2f",
                    contrastText: "#637381",
                },
            }),
            containerPrimary: palette.augmentColor({
                color: {
                    main: "#637381",
                    contrastText: "#black",
                },
            }),
            containerSecondary: palette.augmentColor({
                color: {
                    main: "#637381",
                    contrastText: "#black",
                },
            }),
        },
    },
};
