import { toast } from "react-toastify";

export async function deployCode(code: string,isWiFi:boolean=false) {
    try {
        let response = await fetch("http://localhost:8080/api/v1/esp32/deploy", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                config: {
                    isWifi: isWiFi,
                },
                data: code,
                libraries: []
            })
        })
        let json = await response.json() as {
            status: string,
            message: string
        };
        console.log(json);
        if (json.status.toLowerCase() == "success") {
            toast.success("Code uploaded successfully");
        } else {
            toast.error(json.message);
            // toast.error("Error uploading code");
            // alert("Error uploading code");
        }
    } catch (error) {
        console.log(error);
        toast.error("Error uploading code");
        // alert("Error uploading code")
    }

}
export function getWiFi() {
    let isWiFi_data = localStorage.getItem("isWiFi")
    if (isWiFi_data) {
        if (isWiFi_data === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}

export async function makeRequestOnMachine(ipAddress: string, endPoint: string) {
    try {
        const response = await fetch(`http://${ipAddress}/${endPoint}`)
        return false
    } catch (error) {
        console.error(error);
        return false
    }
}
export async function getDataFromMachine(ipAddress: string, endPoint: string, dataType: "json" | "text" = "text"):Promise<string |boolean | any> {
    try {
        const response = await fetch(`http://${ipAddress}/${endPoint}`)
        if (dataType === "json") {
            return (await response.json())
        } else if (dataType === "text") {
            return (await response.text())
        } else {
            return false
        }
    } catch (error) {
        console.error(error);
        return false
    }
}
