import { Button, Card, CardActions, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AlertDialogBox, ContentDialogBox } from '../../../components/dialog_box'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes/routes'
import { eduCOBOTColorScheme } from '../../../context/SettingsContext'
import DeleteIcon from '@mui/icons-material/Delete';
import { ModelType } from '../../../types/Database'
import { UserContext } from '../../../context/UserContext'
import { BackendConfigs } from '../../../config.environment'
import { toast } from 'react-toastify'

type Props = {}

export default function Models({ }: Props) {
    const navigate = useNavigate()
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isCreateModelDialogOpen, setIsCreateModelDialogOpen] = useState(false)
    const [models, setModels] = useState<ModelType[]>([])
    const [modelName, setModelName] = useState("")
    const [modelDescription, setModelDescription] = useState("")
    const [modelURL, setModelURL] = useState("")
    const [isModelFound, setIsModelFound] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deletingModelData, setDeletingModelData] = useState<ModelType>()
    const [modelData, setModelData] = useState<{
        timeStamp: string
        labels: Array<string>
        imageSize: number
    }
    >({
        "timeStamp": "2024-05-19T10:56:06.595Z",
        "labels": [
            "Shiv",
            "Hand"
        ],
        "imageSize": 224
    })
    const { user } = useContext(UserContext)

    async function getModels() {
        if (user.isLogin) {
            try {
                const response = await fetch(`${BackendConfigs.url}/get-all-models-by-user-id-api`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                    })
                })
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.success) {
                        setModels(data.response)
                    }
                }
            } catch (error) {
                console.log(error);

            }
        }
    }

    async function findModel() {
        try {
            const response = await fetch(modelURL + (modelURL.endsWith("/") ? "metadata.json" : "/metadata.json"))
            const result = await response.json()
            if (!!result) {
                setIsModelFound(true)
                setModelData(result)
            }
        } catch (error) {
            console.log(error);
            setIsModelFound(false)
        }
    }
    useEffect(() => {
        if (modelURL) {
            findModel();
        }
    }, [modelURL])

    async function deleteModel(modelId: string) {
        if (user.isLogin && modelId) {
            try {
                const response = await fetch(`${BackendConfigs.url}/delete-model-api`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        modelId: modelId,
                    })
                })
                if (response.status === 200) {
                    toast.info("Model deleted successfully")
                    setDeleteDialogOpen(false)
                    setDeletingModelData(undefined)
                    getModels()
                } else {
                    toast.error("Access Denied")
                }
            } catch (error) {
                console.log(error);
                toast.error("failed to delete model")
            }
        }
    }


    async function createModel() {
        if (!isModelFound) {
            toast.error("Model not found at this url")
            return
        }
        if (!modelName) {
            toast.error("model name is required")
            return
        }
        if (!modelDescription) {
            toast.error("model description is required")
            return
        }
        if (!modelURL) {
            toast.error("model url is required")
            return
        }
        if (
            !!modelName || !!modelDescription || modelURL || isModelFound || modelData
        ) {
            if (user.isLogin) {
                try {
                    const response = await fetch(`${BackendConfigs.url}/create-Model-api`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: user.userData?.id,
                            name: modelName,
                            description: modelDescription,
                            labels: modelData.labels,
                            modelURL: modelURL,
                        })
                    })
                    if (response.status === 200) {
                        const data = await response.json()
                        if (data.success) {
                            toast.success("Model Created Successfully")
                            setModelName("")
                            setModelDescription("")
                            await getModels()
                            setIsCreateDialogOpen(false)
                            setIsCreateModelDialogOpen(false)
                        } else {
                            toast.error("Access Denied")
                        }
                    }
                } catch (error) {
                    console.log(error);
                    toast.error("Access Denied")
                }
            }
        } else {
            toast.warn("Please fill all the details")
        }

    }
    useEffect(() => {
        getModels()
    }, [user])
    return (
        <div>

            <div style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
            }}>

                <Button color='warning' variant="contained" style={{
                    maxWidth: "150px",
                    width: "100%",
                    minHeight: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bolder",
                    borderRadius: "10px",
                    textTransform: "none",
                }}
                    onClick={() => setIsCreateDialogOpen(true)}
                >
                    Add Model
                </Button>
                <Button variant="contained" style={{
                    maxWidth: "150px",
                    width: "100%",
                    minHeight: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bolder",
                    borderRadius: "10px",
                    textTransform: "none",
                }}
                onClick={() => {
                    setIsCreateModelDialogOpen(true);
                }}
            >
                Create Model
                </Button>
            </div>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "20px 0",
                gap: "20px",
            }}>
                {
                    models.length === 0 && <>
                        <Card style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            borderRadius: "20px",
                        }}
                        >
                            <h1 style={{
                                textAlign: "center",
                            }}>No Models found</h1>

                        </Card>
                    </>
                }
                {
                    models && models.length > 0 && models.map((model) => {
                        return <Card key={model._id} style={{
                            width: "250px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            borderRadius: "20px",
                            position: "relative"
                        }}
                        sx={{
                            ":hover": {
                                transform: "scale(1.1)",
                            }
                        }}
                        >
                            <DeleteIcon sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='error' onClick={()=>{
                                setDeletingModelData(model);
                                setDeleteDialogOpen(true);
                            }}/>

                            <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" alt="" style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "20px",
                            }} />
                            <CardActions style={{
                                width: "100%",
                                background: "#DDDDDD",
                                textAlign: "center",
                                marginBottom: "0px"
                            }}>
                                <h5 style={{
                                    width: "100%",
                                    color: eduCOBOTColorScheme.gray,
                                    textAlign: "center",
                                }} onClick={() => {
                                    navigate(routes.DASHBOARD.MODELING_WORKSPACE_ROOT + "/" + model._id)
                                }}>

                                    {model.name}

                                </h5>
                            </CardActions>

                        </Card>

                    })
                }
            </div>
            <ContentDialogBox
                isOpen={isCreateModelDialogOpen}
                onClose={() => setIsCreateModelDialogOpen(false)}
                title="Create Google Project"
                transitionDirection="down"
                isTransition={true}
                content={<>
                    <div style={{
                        padding: "10px 30px"
                    }}>
                        <ol>
                            <li>Click on Start Button</li>
                            <li>New Popup Wind will be get opened add your data or capture your data and train the model </li>
                            <li>Click on Export Model</li>
                            <li>Get sharable link for the model</li>
                            <li>once you get model link, close the window</li>
                            <li>Now Click on add model here</li>
                            <li>Enter model name and description</li>
                            <li>Paste the URL of teachable machine</li>
                            <li>verify the classes names</li>
                            <li>Click on Create, your model will be get created</li>
                        </ol>
                    </div>
                </>}
                actions={<>
                    <Button onClick={() => {
                        setIsCreateDialogOpen(true);
                    }} size='small' variant="contained" color="primary" sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px"
                    }}>
                        Add Model
                    </Button>
                    <Button onClick={() => {
                        window.open("https://teachablemachine.withgoogle.com/train/image", "Teachable Machine", "width=1600,height=1000");
                    }} size='small' variant="contained" color="primary" sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px"
                    }}>
                        Start
                    </Button>
                </>}
                maxWidth="md"
                fullScreen={false}
            />
            <ContentDialogBox
                isOpen={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                title="Add Google Project"
                transitionDirection="down"
                isTransition={true}
                content={<>
                    <div style={{
                        padding: 10
                    }}>
                        <TextField
                            fullWidth
                            label='Enter Project Name'
                            style={{
                                margin: 10
                            }}
                            value={modelName}
                            onChange={(event) => {
                                setModelName(event.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            label='Enter Project Description'
                            style={{
                                margin: 10
                            }}
                            value={modelDescription}
                            onChange={(event) => {
                                setModelDescription(event.target.value)
                            }}
                        />
                        <TextField
                            fullWidth
                            label='Enter Teachable URL'
                            style={{
                                margin: 10
                            }}
                            value={modelURL}
                            onChange={(event) => {
                                setModelURL(event.target.value)
                            }}
                        />
                    </div>
                    <div>
                        {
                            isModelFound && modelData && <>
                                <h4 style={{
                                    textAlign: "center"
                                }}>Class list from this model</h4>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}>
                                    {
                                        modelData.labels.map((label, index) => (
                                            <Button size='small' variant='outlined' sx={{
                                                margin: "5px"
                                            }}>{label}</Button>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                </>}
                actions={<>
                    <Button onClick={createModel} size='small' disabled={!isModelFound} variant="contained" color="primary" sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px"
                    }}>
                        Create
                    </Button>
                </>}
                maxWidth="md"
                fullScreen={false}
            />
            <AlertDialogBox
                isOpen={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false)
                    setDeletingModelData(undefined)
                }}
                title={'Delete Model-' + deletingModelData?.name + "?"}
                successAction={<>
                    <Button size='small' sx={{
                        textTransform: "none",
                        borderRadius:"6px",
                        fontWeight:"bolder",
                        fontSize:"10px"
                    }} variant='contained' color='error' onClick={() => {
                        if (deletingModelData) {
                            deleteModel(deletingModelData._id)
                        }
                    }}>
                        Delete
                    </Button>
                </>}
            />

        </div>
    )
}