import { Card } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes/routes'
import { eduCOBOTColorScheme, getRandomValueForEducobotColors } from '../../../context/SettingsContext'

type Props = {}

export default function Extensions({ }: Props) {
    const router = useNavigate()
    return (
        <div>
            <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                margin: "20px 0",
                gap: "20px",
            }}>

                <Card style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius:"20px",
                }}
                    onClick={() => {
                        router(routes.TRIAL_EXTENSIONS.FACE_DETECTION)
                    }}
                >

                    <img src="/Innovator_Bulb.png" alt="" style={{
                        width: "100px",
                        height: "100px",
                    }} />

                    <h3 >
                        Face Detection
                    </h3>

                </Card>
                {/* <Card style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                    onClick={() => {
                        router(routes.TRIAL_EXTENSIONS.OBJECT_DETECTION)
                    }}
                >

                    <img src="/Innovator_Bulb.png" alt="" style={{
                        width: "300px",
                        height: "300px",
                    }} />

                    <h3>
                        Object Detection
                    </h3>

                </Card>
                <Card style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                    onClick={() => {
                        router(routes.TRIAL_EXTENSIONS.POSE_DETECTION)
                    }}
                >

                    <img src="/Innovator_Bulb.png" alt="" style={{
                        width: "300px",
                        height: "300px",
                    }} />

                    <h3>
                        Pose Detection
                    </h3>

                </Card> */}

            </div>
        </div>
    )
}