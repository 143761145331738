import React from 'react'
// import GoogleTeachableExecuter from './GoogleTeachableExecuter'

type Props = {}

export default function Extension({}: Props) {
  return (
    <div>
      {/* <GoogleTeachableExecuter/> */}
    </div>
  )
}