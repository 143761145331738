import Blockly from "blockly";
import { BlockColors } from "./color-mapping";
import { audios } from "../audio";
import { images } from "../image";


Blockly.Blocks['play_audio'] = {
    init: function () {
        this.appendValueInput("audio")
            .appendField("Play Audio");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Audio);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['stop_audio'] = {
    init: function () {
        this.appendDummyInput("delay")
            .appendField("Stop Audio");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Audio);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Object.keys(audios).forEach(function (key) {

    Blockly.Blocks[key] = {
        init: function () {
            this.appendDummyInput("delay")
                .appendField(key.replace("_audio", ""));
            this.setOutput(true, "String");
            this.setColour(BlockColors.Audio);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
})



Blockly.Blocks['open_image'] = {
    init: function () {
        this.appendValueInput("image")
            .appendField("Show Image");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Image);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.Blocks['hide_image'] = {
    init: function () {
        this.appendDummyInput("hide")
            .appendField("Hide Image");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setColour(BlockColors.Image);
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Object.keys(images).forEach(function (key) {

    Blockly.Blocks[key] = {
        init: function () {
            this.appendDummyInput("delay")
                .appendField(key.replace("_audio", ""));
            this.setOutput(true, "String");
            this.setColour(BlockColors.Image);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
})



export default Blockly