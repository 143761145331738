import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Slider,
    Switch,
    TextField,
    TextFieldProps,
    Typography,
    styled,
    useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack"; import { UserContext } from "../../context/UserContext"
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, SettingContext } from '../../context/SettingsContext'
import { Helmet } from 'react-helmet-async'
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ToggleButton from "@mui/material/ToggleButton";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { FadingContentLoader } from "../../components/loaders/ContentLoaders";
import { toast } from "react-toastify";
import ComingSoonCard from "../../components/templates/ComingSoonCard";
import { SiteLoader } from "../../components/loaders";
import { LineWaveLoader } from "../../components/loaders/ContainerLoaders";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";

type Props = {}

function Home({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)
    const navigate=useNavigate()
    useEffect(()=>{
        navigate(routes.DASHBOARD.ROOT)
    },[])
    return (
        <>
            {/* <ComingSoonCard /> */}
        </>
    )
}




export default Home