import { Button, Card, CardActionArea, CardActions, TextField } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AlertDialogBox, ContentDialogBox } from '../../../components/dialog_box'
import { eduCOBOTColorScheme, getRandomValueForEducobotColors } from '../../../context/SettingsContext'
import { BackendConfigs } from '../../../config.environment'
import { UserContext } from '../../../context/UserContext'
import { toast } from 'react-toastify'
import { WorkspaceType } from '../../../types/Database'
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {}



export default function Workspace({ }: Props) {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([])
    const [workspaceName, setWorkSpaceName] = useState("")
    const [workspaceDescription, setWorkSpaceDescription] = useState("")
    const { user } = useContext(UserContext)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deletingWorkspaceData, setDeletingWorkspaceData] = useState<WorkspaceType>()

    async function getWorkspaces() {
        if (user.isLogin) {
            try {
                const response = await fetch(`${BackendConfigs.url}/get-all-workspace-by-user-id-api`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                    })
                })
                if (response.status === 200) {
                    const data = await response.json()
                    if (data.success) {
                        setWorkspaces(data.response)
                    }
                }
            } catch (error) {
                console.log(error);

            }
        }
    }

    async function deleteWorkspace(workspaceId: string) {
        if (user.isLogin && workspaceId) {
            try {
                const response = await fetch(`${BackendConfigs.url}/delete-workspace-api`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        workspaceId: workspaceId,
                    })
                })
                if (response.status === 200) {
                    toast.info("Model deleted successfully")
                    setDeleteDialogOpen(false)
                    setDeletingWorkspaceData(undefined)
                    getWorkspaces()
                } else {
                    toast.error("Access Denied")
                }
            } catch (error) {
                console.log(error);
                toast.error("failed to delete model")
            }
        }
    }

    async function createWorkspace() {
        if (
            !!workspaceName || !!workspaceDescription
        ) {
            if (user.isLogin) {
                try {
                    const response = await fetch(`${BackendConfigs.url}/create-workspace-api`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: user.userData?.id,
                            name: workspaceName,
                            description: workspaceDescription,
                        })
                    })
                    if (response.status === 200) {
                        const data = await response.json()
                        if (data.success) {
                            toast.success("Workspace Created Successfully")
                            setWorkSpaceName("")
                            setWorkSpaceDescription("")
                            await getWorkspaces()
                            setIsCreateDialogOpen(false)
                        } else {
                            toast.error("Access Denied")
                        }
                    }
                } catch (error) {
                    console.log(error);
                    toast.error("Access Denied")
                }
            }
        } else {
            toast.warn("Please fill all the details")
        }

    }
    useEffect(() => {
        getWorkspaces()
    }, [])
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "end",
            }}>

                <Button variant="contained" style={{
                    maxWidth: "200px",
                    width: "100%",
                    minHeight: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bolder",
                    borderRadius: "10px",
                    textTransform: "none",
                }}
                    onClick={() => setIsCreateDialogOpen(true)}
                >
                    Create Project
                </Button>
            </div>
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                margin: "20px 0",
                gap: "20px",
            }}>
                {
                    workspaces.length === 0 && <>
                        <Card style={{
                            width: "100%",
                            minHeight: "100px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            borderRadius: "20px",
                            padding: "20px",
                        }}
                        >
                            <h3 style={{
                                textAlign: "center",
                            }}>No Workspaces found</h3>

                        </Card>
                    </>
                }
                {
                    workspaces && workspaces.length > 0 && workspaces.map((workspace) => {
                        return <Card style={{
                            width: "250px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            borderRadius: "20px",
                        }}

                        >
                            <DeleteIcon sx={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                                transition: "all 0.5s",
                                ":hover": {
                                    scale: "1.5",
                                }
                            }} color='error' onClick={() => {
                                setDeletingWorkspaceData(workspace);
                                setDeleteDialogOpen(true);
                            }} />
                            <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" alt="" style={{
                                width: "100px",
                                height: "100px",
                                marginBottom: "20px",
                            }} />
                            <CardActions style={{
                                width: "100%",
                                background: "#DDDDDD",
                                textAlign: "center",
                                marginBottom: "0px"
                            }}>
                                <h5 style={{
                                    width: "100%",
                                    color: eduCOBOTColorScheme.gray,
                                    textAlign: "center",
                                }} onClick={() => {
                                    window.location.href = `/dashboard/workspace-editor/${workspace._id}`
                                }}>
                                    {
                                        workspace.name
                                    }
                                </h5>
                            </CardActions>
                        </Card>
                    })
                }

            </div>
            <ContentDialogBox
                isOpen={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                title="Create Project"
                transitionDirection="down"
                isTransition={true}
                content={<>
                    <div style={{
                        padding: 10
                    }}>
                        <TextField
                            fullWidth
                            label='Enter Project Name'
                            style={{
                                margin: 10
                            }}
                            value={workspaceName}
                            onChange={(e) => {
                                if (e.target.value.length > 30) {
                                    alert("Length should not be grater than 30 characters")
                                } else {
                                    setWorkSpaceName(e.target.value)
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            label='Enter Project Description'
                            style={{
                                margin: 10
                            }}
                            value={workspaceDescription}
                            onChange={(e) => {
                                setWorkSpaceDescription(e.target.value)
                            }}
                        />
                    </div>
                </>}
                actions={<>
                    <Button variant="contained" color="primary" onClick={createWorkspace} sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px"
                    }} size='small'>
                        Create
                    </Button>
                </>}
                maxWidth="md"
                fullScreen={false}
            />
            <AlertDialogBox
                isOpen={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false)
                    setDeletingWorkspaceData(undefined)
                }}
                title={'Delete Workspace-' + deletingWorkspaceData?.name + "?"}
                successAction={<>
                    <Button size='small' sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px"
                    }} variant='contained' color='error' onClick={() => {
                        if (deletingWorkspaceData) {
                            deleteWorkspace(deletingWorkspaceData._id)
                        }
                    }}>
                        Delete
                    </Button>
                </>}
            />
        </div>
    )
}