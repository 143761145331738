export const BlockColors = {
    "Section Color": "#DDDDDD",
    "Time": "#84878A",
    "Machine": "#848700",
    "Loop": "#0FBD8C",
    "Variables": "#A55B80",
    "Conditions": "#4C97FF",
    "Constants": "#EA0075",
    "Functions": "#205273",
    "Math": "#006400",
    "IR Sensor": "#a3d5ff",
    "Touch Sensor": "#00f00f",
    "LDR Sensor": "#d3a1a1",
    "Ultra Sonic Sensor": "#f25793",
    "DHT11 Sensor": "#0b7669",
    "Keypad": "#0092a6",
    "RFID Sensor": "#ba6d00",
    "LED": "#990000",
    "Buzzer": "#9f2c79",
    "Motor": "#b76e79",
    "LCD": "#316ccd",
    "WiFi": "#400c16",
    "IOT": "#FF0c16",
    "Bluetooth": "#142c53",
    "Servo": "#006455",
    "8x8  Matrix": "#ba6d55",
}
