import { Order, JavascriptGenerator } from 'blockly/javascript';

import Blockly from "./javascript-blocks";
import { Workspace } from 'blockly';
import {
    lists_create_empty, lists_create_with, lists_repeat
    , lists_length
    , lists_isEmpty
    , lists_indexOf
    , lists_getIndex
    , lists_setIndex
    , lists_getSublist
    , lists_sort
    , lists_split
    , lists_reverse
    , controls_if
    , logic_compare
    , logic_operation
    , logic_negate
    , logic_boolean
    , logic_null
    , logic_ternary
    , controls_repeat_ext
    , controls_whileUntil
    , controls_for
    , controls_forEach
    , controls_flow_statements
    , math_number
    , math_arithmetic
    , math_single
    , math_constant
    , math_number_property
    , math_change
    , math_on_list
    , math_modulo
    , math_constrain
    , math_random_int
    , math_random_float
    , math_atan2
    , procedures_defreturn
    , procedures_callreturn
    , procedures_callnoreturn
    , procedures_ifreturn
    , text
    , text_join
    , text_append
    , text_length
    , text_isEmpty
    , text_indexOf
    , text_charAt
    , text_getSubstring
    , text_changeCase
    , text_trim
    , text_print
    , text_prompt_ext
    , text_count
    , text_replace
    , text_reverse
    , variables_get
    , variables_set
} from './javascript-traditional-blocks-resolver';
import JavascriptBlockToolBox from './javascript-toolbox';
import { BlockColors } from './color-mapping';
import { audios } from '../audio';
import { images } from '../image';

const javascriptGenerator = new JavascriptGenerator()



export class JavascriptBlockResolverEditor {
    javascriptResolver: JavascriptGenerator
    import_statements: string;
    initialization_statements: string;
    constructor(javascriptResolver: JavascriptGenerator) {
        this.javascriptResolver = javascriptResolver;
        this.import_statements = "";
        this.initialization_statements = "";
    }
    getJavascriptCode(workspace: Workspace) {
        const javascriptCode = this.import_statements + this.initialization_statements + this.javascriptResolver.workspaceToCode(workspace);
        this.import_statements = "";
        this.initialization_statements = "";
        return javascriptCode
    }
}

const javascriptResolver = new JavascriptBlockResolverEditor(javascriptGenerator);

javascriptGenerator.forBlock['lists_create_empty'] = lists_create_empty
javascriptGenerator.forBlock['lists_create_with'] = lists_create_with
javascriptGenerator.forBlock['lists_repeat'] = lists_repeat
javascriptGenerator.forBlock['lists_length'] = lists_length
javascriptGenerator.forBlock['lists_isEmpty'] = lists_isEmpty
javascriptGenerator.forBlock['lists_indexOf'] = lists_indexOf
javascriptGenerator.forBlock['lists_getIndex'] = lists_getIndex
javascriptGenerator.forBlock['lists_setIndex'] = lists_setIndex
javascriptGenerator.forBlock['lists_getSublist'] = lists_getSublist
javascriptGenerator.forBlock['lists_sort'] = lists_sort
javascriptGenerator.forBlock['lists_split'] = lists_split
javascriptGenerator.forBlock['lists_reverse'] = lists_reverse
javascriptGenerator.forBlock['controls_if'] = controls_if
javascriptGenerator.forBlock['logic_compare'] = logic_compare
javascriptGenerator.forBlock['logic_operation'] = logic_operation
javascriptGenerator.forBlock['logic_negate'] = logic_negate
javascriptGenerator.forBlock['logic_boolean'] = logic_boolean
javascriptGenerator.forBlock['logic_null'] = logic_null
javascriptGenerator.forBlock['logic_ternary'] = logic_ternary
javascriptGenerator.forBlock['controls_repeat_ext'] = controls_repeat_ext
javascriptGenerator.forBlock['controls_whileUntil'] = controls_whileUntil
javascriptGenerator.forBlock['controls_for'] = controls_for
javascriptGenerator.forBlock['controls_forEach'] = controls_forEach
javascriptGenerator.forBlock['controls_flow_statements'] = controls_flow_statements
javascriptGenerator.forBlock['math_number'] = math_number
javascriptGenerator.forBlock['math_arithmetic'] = math_arithmetic
javascriptGenerator.forBlock['math_single'] = math_single
javascriptGenerator.forBlock['math_constant'] = math_constant
javascriptGenerator.forBlock['math_number_property'] = math_number_property
javascriptGenerator.forBlock['math_change'] = math_change
javascriptGenerator.forBlock['math_on_list'] = math_on_list
javascriptGenerator.forBlock['math_modulo'] = math_modulo
javascriptGenerator.forBlock['math_constrain'] = math_constrain
javascriptGenerator.forBlock['math_random_int'] = math_random_int
javascriptGenerator.forBlock['math_random_float'] = math_random_float
javascriptGenerator.forBlock['math_atan2'] = math_atan2
javascriptGenerator.forBlock['procedures_defreturn'] = procedures_defreturn
javascriptGenerator.forBlock['procedures_callreturn'] = procedures_callreturn
javascriptGenerator.forBlock['procedures_callnoreturn'] = procedures_callnoreturn
javascriptGenerator.forBlock['procedures_ifreturn'] = procedures_ifreturn
javascriptGenerator.forBlock['text'] = text
javascriptGenerator.forBlock['text_join'] = text_join
javascriptGenerator.forBlock['text_append'] = text_append
javascriptGenerator.forBlock['text_length'] = text_length
javascriptGenerator.forBlock['text_isEmpty'] = text_isEmpty
javascriptGenerator.forBlock['text_indexOf'] = text_indexOf
javascriptGenerator.forBlock['text_charAt'] = text_charAt
javascriptGenerator.forBlock['text_getSubstring'] = text_getSubstring
javascriptGenerator.forBlock['text_changeCase'] = text_changeCase
javascriptGenerator.forBlock['text_trim'] = text_trim
javascriptGenerator.forBlock['text_print'] = text_print
javascriptGenerator.forBlock['text_prompt_ext'] = text_prompt_ext
javascriptGenerator.forBlock['text_count'] = text_count
javascriptGenerator.forBlock['text_replace'] = text_replace
javascriptGenerator.forBlock['text_reverse'] = text_reverse
javascriptGenerator.forBlock['variables_get'] = variables_get
javascriptGenerator.forBlock['variables_set'] = variables_set





javascriptGenerator.forBlock['is_face_detected'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var value_name = javascriptGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    var code = ``;
    return [code, Order.ATOMIC];
}

javascriptGenerator.forBlock['get_number_of_faces'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var value_name = javascriptGenerator.valueToCode(block, 'NAME', Order.ATOMIC);
    //import statements
    var code = ``;
    return [code, Order.ATOMIC];
}

javascriptGenerator.forBlock['play_audio'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var value_name = javascriptGenerator.valueToCode(block, 'audio', Order.ATOMIC);
    //import statements
    var code = `audioPlayer.play(${value_name})\n`;
    return code;
}

javascriptGenerator.forBlock['stop_audio'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var code = `audioPlayer.stop()\n`;
    return code;
}

Object.keys(audios).forEach(function (key){
    javascriptGenerator.forBlock[key] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
        var code = `'${audios[key]}'`;
        return [code, Order.ATOMIC];
    }
    
})



javascriptGenerator.forBlock['open_image'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var value_name = javascriptGenerator.valueToCode(block, 'image', Order.ATOMIC);
    //import statements
    var code = `showImage(${value_name})\n`;
    return code;
}


javascriptGenerator.forBlock['hide_image'] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
    var code = `hideImage()\n`;
    return code;
}


Object.keys(images).forEach(function (key){
    javascriptGenerator.forBlock[key] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
        var code = `'${images[key]}'`;
        return [code, Order.ATOMIC];
    }
    
})


export function generateBlocklyBlocksForMicroPythonDynamically(block_names: string[], Old_ToolBox: typeof JavascriptBlockToolBox, group: string) {
    let new_tool_box = Old_ToolBox
    let new_group_contents = [] as {
        kind: string,
        type: string
    }[]

    block_names.forEach(function (block_name) {
        Blockly.Blocks[block_name + "function"] = {
            init: function () {
                this.appendDummyInput()
                    .appendField("Request " + block_name + " on Machine");
                this.setPreviousStatement(true, null);
                this.setNextStatement(true, null);
                this.setColour(BlockColors['Machine']);
                this.setTooltip("");
                this.setHelpUrl("");
            }
        };
        javascriptGenerator.forBlock[block_name + "function"] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
            var code = `call_machine_function_on_callback(${block_name})`;
            return code;
        }
        new_group_contents.push({
            "kind": "BLOCK",
            "type": block_name + "function",
        })
    })
    let final_group_content = {
        "kind": "CATEGORY",
        "contents": new_group_contents,
        "name": group,
        "colour": BlockColors['Machine']
    }
    let index = -1
    for (let i = 0; i < new_tool_box.contents.length; i++) {
        if (new_tool_box.contents[i].name === group) {
            index = i;
            break
        }
    }
    if (index !== -1) {
        new_tool_box.contents[index] = final_group_content
    } else {
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push(final_group_content)
    }

    return new_tool_box
}



export function generateBlocklySBlocksForModelOutputDynamically(block_names: string[], Old_ToolBox: typeof JavascriptBlockToolBox, group: string) {
    let new_tool_box = Old_ToolBox

    let new_group_contents = [] as {
        kind: string,
        type: string
    }[]
    Blockly.Blocks['model_output'] = {
        init: function () {
            this.appendDummyInput()
                .appendField("getPredictionByModel()");
            this.setOutput(true, "String");
            this.setColour(BlockColors['Model']);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
    block_names.forEach(function (block_name) {

        Blockly.Blocks[block_name + "return"] = {
            init: function () {
                this.appendDummyInput()
                    .appendField(block_name);
                this.setOutput(true, "string");
                this.setColour(BlockColors['Model']);
                this.setTooltip("");
                this.setHelpUrl("");
            }
        };
        javascriptGenerator.forBlock[block_name + "return"] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
            return [`'${block_name}'`, Order.ATOMIC];
        }
        new_group_contents.push({
            "kind": "BLOCK",
            "type": block_name + "return"
        })
    })
    Blockly.Blocks["get_output"] = {
        init: function () {
            this.appendDummyInput()
                .appendField("getFinalPrediction()");
            this.setOutput(true, "string");
            this.setColour(BlockColors['Model']);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock["get_output"] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
        return ["getFinalPrediction()", Order.ATOMIC];
    }
    new_group_contents.push({
        "kind": "BLOCK",
        "type": "get_output"
    })

    let final_group_content = {
        "kind": "CATEGORY",
        "contents": new_group_contents,
        "name": group,
        "colour": BlockColors['Model']
    }
    let index = -1
    for (let i = 0; i < new_tool_box.contents.length; i++) {
        if (new_tool_box.contents[i].name === group) {
            index = i;
            break
        }
    }
    if (index !== -1) {
        new_tool_box.contents[index] = final_group_content
    } else {
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push(final_group_content)
    }

    return new_tool_box
}




export function generateBlocklyBlocksForAudiosDynamically(block_names: string[],block_values:string[], Old_ToolBox: typeof JavascriptBlockToolBox, group: string) {
    let new_tool_box = Old_ToolBox
    let new_group_contents = [] as {
        kind: string,
        type: string
    }[]
    Blockly.Blocks['model_output'] = {
        init: function () {
            this.appendDummyInput()
                .appendField("getPredictionByModel()");
            this.setOutput(true, "String");
            this.setColour(230);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
    block_names.forEach(function (block_name) {

        Blockly.Blocks[block_name + "return"] = {
            init: function () {
                this.appendDummyInput()
                    .appendField(block_name);
                this.setOutput(true, "string");
                this.setColour(230);
                this.setTooltip("");
                this.setHelpUrl("");
            }
        };
        javascriptGenerator.forBlock[block_name + "return"] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
            return [`'${block_name}'`, Order.ATOMIC];
        }
        new_group_contents.push({
            "kind": "BLOCK",
            "type": block_name + "return"
        })
    })
    Blockly.Blocks["get_output"] = {
        init: function () {
            this.appendDummyInput()
                .appendField("getFinalPrediction()");
            this.setOutput(true, "string");
            this.setColour(230);
            this.setTooltip("");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock["get_output"] = function (block: Blockly.Block, javascriptGenerator: JavascriptGenerator) {
        return ["getFinalPrediction()", Order.ATOMIC];
    }
    new_group_contents.push({
        "kind": "BLOCK",
        "type": "get_output"
    })

    let final_group_content = {
        "kind": "CATEGORY",
        "contents": new_group_contents,
        "name": group,
        "colour": BlockColors['DHT11 Sensor']
    }
    let index = -1
    for (let i = 0; i < new_tool_box.contents.length; i++) {
        if (new_tool_box.contents[i].name === group) {
            index = i;
            break
        }
    }
    if (index !== -1) {
        new_tool_box.contents[index] = final_group_content
    } else {
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push({
            "kind": "SEP",
        } as any)
        new_tool_box.contents.push(final_group_content)
    }

    return new_tool_box
}







export default javascriptResolver

export { javascriptGenerator }






















