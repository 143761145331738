import React, { useContext } from 'react'
import { UserContext } from '../../context/UserContext'
import { SettingContext } from '../../context/SettingsContext'
import Dashboard from './Dashboard'
import DashboardLayout from './DashboardConfigs/DashboardLayout'

type Props = {}

function DashboardPage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    return (
        <div>
            <DashboardLayout title='Dashboard'>
                <Dashboard/>
            </DashboardLayout>
        </div>
    )
}

export default DashboardPage