import React, { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { SettingContext } from '../../../context/SettingsContext'
import DashboardLayout from '../DashboardConfigs/DashboardLayout'
import Workspace from './Workspace'

type Props = {}

export default function WorkspacePage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    return (
        <div>
            <DashboardLayout title='Workspace'>
                <Workspace />

            </DashboardLayout>
        </div>
    )
}