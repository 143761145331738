export const routes={
    ROOT:'/',
    PROJECTS:{
        ROOT:"/projects",
        PROJECT:"/projects/:projectId",
    },
    ROBOTICS_IDE:"/robotics-ide",
    DASHBOARD:{
        DASHBOARD:"/dashboard",
        ROOT:"/dashboard/main",
        WORKSPACES:"/dashboard/workspaces",
        WORKSPACE_EDITOR:"/dashboard/workspace-editor/:workspaceId", 
        MODELS:"/dashboard/models",
        MODELING_WORKSPACE_ROOT:"/dashboard/modeling-workspace",
        MODELING_WORKSPACE:"/dashboard/modeling-workspace/:modelId",
        EXTENSIONS:"/dashboard/extensions",
        EXTENSION:"/dashboard/extensions/:extensionId",
    },
    TRIAL_EXTENSIONS:{
        ROOT:"/trials-extension",
        FACE_DETECTION:"/trials-extension/face-detection",
        OBJECT_DETECTION:"/trials-extension/object-detection",
        POSE_DETECTION:"/trials-extension/pose-detection",
    },
    NOTFOUND:'/404',
    WILDCARD:"*",
}