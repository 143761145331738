

import React from 'react'
import { Hourglass, LineWave } from 'react-loader-spinner'
import { motion } from "framer-motion"
import { framerAnimation } from '../../theme/animation/MotionConfigs'
import { Card } from '@mui/material'
import { eduCOBOTColorScheme } from '../../context/SettingsContext'
type Props = {}

function ContainerLoader({ height = "100", width = "100", visible = true, animation = framerAnimation.left2right, message="Processing..." }) {
    return (
        <motion.div
            initial={animation.initial}
            animate={animation.animate}
            exit={animation.exit}
            transition={animation.transition}
        >
            <Card style={{ height: height, width: width, backdropFilter: "blur(2px)", background:"transparent", padding:"50px 0", borderRadius:"10px" }}>

                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/image-processing20201206-22576-unscreen.gif" alt="" />
                <h5 style={{
                    textAlign: "center",
                    color:eduCOBOTColorScheme.gray
                }}>{message}</h5>
            </Card>
        </motion.div>
    )
}
export default ContainerLoader



export function LineWaveLoader({ height = "100", width = "100", visible = true, animation = framerAnimation.left2right }) {
    return (
        <motion.div
            initial={animation.initial}
            animate={animation.animate}
            exit={animation.exit}
            transition={animation.transition}
        >
            <LineWave
                visible={visible}
                height={height}
                width={width}
                ariaLabel="loading"
                wrapperStyle={{}}
                wrapperClass=""
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
            />
        </motion.div>
    )
}

export function HourglassLoader({ height = "100", width = "100", visible = true, animation = framerAnimation.left2right }) {
    return (
        <motion.div
            initial={animation.initial}
            animate={animation.animate}
            exit={animation.exit}
            transition={animation.transition}
        >
            {/* <Hourglass
                visible={visible}
                height={height}
                width={width}
                ariaLabel="loading"
                wrapperStyle={{}}
                wrapperClass=""
            /> */}











                {/* <iframe src="/circuit-loader" style={{ width: "99vw", height: "99vh" }}></iframe> */}



            <div className="container">
                <div className="loader">
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                </div>
            </div>
        </motion.div>
    )
}
