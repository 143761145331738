import React from 'react'
import { routes } from '../../../routes/routes'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Blocks, Container } from 'lucide-react';


function DashboardNavbarIcon(icon: string, size: string = "20px") {
    switch (icon) {
        case routes.DASHBOARD.ROOT:
            return <DashboardIcon style={{
                fontSize: size,
            }} />
        case routes.DASHBOARD.WORKSPACES:
            return <DeveloperBoardIcon style={{
                fontSize: size,
            }} />
        case routes.DASHBOARD.MODELS:
            return <Container size={size} />
        case routes.DASHBOARD.EXTENSIONS:
            return <Blocks size={size} />
        default:
            return <ConstructionIcon style={{
                fontSize: size,
            }} />
    }
}

export default DashboardNavbarIcon

export const 
DashboardNavConfig = {
    navigation: [
        {
            title: "Dashboard",
            link: routes.DASHBOARD.ROOT
        },
        {
            title: "Workspace",
            link: routes.DASHBOARD.WORKSPACES
        },
        {
            title: "Models",
            link: routes.DASHBOARD.MODELS
        },
        {
            title: "Extensions",
            link: routes.DASHBOARD.EXTENSIONS
        },
        {
            title: "Robotics Editor ",
            link: routes.ROBOTICS_IDE
        },
    ]
}