import Webcam from 'react-webcam';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import { useEffect } from 'react';
import { Button } from '@mui/material';

type Props = {
    setPrediction?: (prediction: any) => void;
    height?: number,
    width?: number,
    isPreview?: boolean,
    setFinalPrediction?: (prediction: any) => void,
}

export default function FaceDetectionExecuter({ setPrediction, height = 480, width = 640, isPreview = false }: Props) {
    const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
        faceDetectionOptions: {
            model: 'short',
        },
        faceDetection: new FaceDetection.FaceDetection({
            locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
        }),
        camera: ({ mediaSrc, onFrame }: CameraOptions) =>
            new Camera(mediaSrc, {
                onFrame,
                width,
                height,
            }),
    });
    useEffect(() => {
        setPrediction && setPrediction({
            detected,
            facesDetected
        })
    }, [detected, facesDetected])
    return (
        <div style={{
            display: isPreview ? 'flex' : "none",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            width: "100%",
            padding: "10px",
            flexDirection: "column"
        }}>

            <div style={{
                border: "1px solid gray",
                borderRadius: "20px",
                overflow: "hidden",
            }}>
                <div style={{ width, height, position: 'relative', overflow: "hidden" }}>
                    {boundingBox.map((box, index) => (
                        <div
                            key={`${index + 1}`}
                            style={{
                                border: '4px solid red',
                                position: 'absolute',
                                top: `${box.yCenter * 100}%`,
                                left: `${box.xCenter * 100}%`,
                                width: `${box.width * 100}%`,
                                height: `${box.height * 100}%`,
                                zIndex: 1,
                            }}
                        />
                    ))}
                    <Webcam
                        ref={webcamRef}
                        forceScreenshotSourceSize
                        style={{
                            height,
                            width,
                            position: 'absolute',
                        }}
                    />
                </div>
                {/* <p>{`Loading: ${isLoading}`}</p> */}
                <div style={{
                    padding: "20px"
                }}>

                    <p>Face Detected:

                        <Button variant='outlined' color={detected === true ? "success" : "error"} size='small' sx={{
                            marginLeft: "20px"
                        }}>

                            <span style={{
                                fontWeight: "bolder",
                            }}>

                                {`${detected === true ? "Yes" : "No"}`}

                            </span>
                        </Button>


                    </p>
                    <p>Number of faces detected:


                        <Button variant='outlined' color={facesDetected > 0 ? "success" : "error"} size='small' sx={{
                            marginLeft: "20px"
                        }} >

                            <span style={{
                                fontWeight: "bolder",
                            }}>
                                {`${facesDetected}`}

                            </span>
                        </Button>
                    </p>
                </div>
            </div>
        </div>
    );

}