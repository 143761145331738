import React, { useContext, useEffect } from 'react'
import { SettingContext } from '../../../../context/SettingsContext'
import { Button, Switch, Tooltip, Typography } from '@mui/material'
import MicroPythonEditor from '../../../../components/micropython-block-editor'
import CodeEditor from '../../../../components/code-editor'
import { deployCode } from '../../../../components/micropython-block-editor/RoboticHandler'
import { motion } from 'framer-motion';
import { framerAnimation } from '../../../../theme/animation/MotionConfigs'
import { ControllerComponent, getWiFi } from '../../../MicropythonEditor'
import { toast } from 'react-toastify'
import { ConnectionContext } from '../../../../context/ConnectionContext'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import { ContentDialogBox } from '../../../../components/dialog_box'

type Props = {
    code: string,
    setCode: (code: string) => void,
    setXml: (xml: string) => void,
    setNewBlocks?: (newBlocks: string[]) => void,
    newBlocks: string[]
}

export default function MicroPythonEditorForWorkspace({
    code,
    setCode,
    setXml,
    setNewBlocks,
    newBlocks
}: Props) {
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(true)
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())
    const { connection, handleConnectionTab } = useContext(ConnectionContext)

    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])
    async function deployCodeOnMachine() {
        toast.warning("software not linked for ML")
    }
    return (
        <motion.div
            initial={framerAnimation.left2right.initial}
            animate={framerAnimation.left2right.animate}
            exit={framerAnimation.left2right.exit}
            transition={framerAnimation.left2right.transition}
            style={{
                height: "100%",
                zIndex: 999999,
            }}
        >

            <div style={{
                display: "flex",
                height: "100%",
            }}>
                <MicroPythonEditor setXml={setXml} setCode={(code: string) => {
                    isBlockEditing && setCode(code)
                }} setNewBlocks={setNewBlocks} />
                <div style={{
                    height: "88dvh",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "5vh",
                        zIndex: 999999
                    }}>
                        <div>
                            <Tooltip title="Deploy">
                                <Button style={{
                                    border: "none",
                                }} onClick={() => {
                                    deployCodeOnMachine()
                                }}>
                                    <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                        width: "20px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>

                            <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>
                                <Button variant='outlined' onClick={() => {
                                    setIsBlockEditing(!isBlockEditing)
                                }} style={{
                                    border: "none",
                                    width: "50px"
                                }}>
                                    {
                                        isBlockEditing ?
                                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                            : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                filter: "invert(1)",
                                            }} />
                                    }
                                </Button>
                            </Tooltip>
                        </div>
                        <Typography sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Tooltip title="Controller">
                                <Button style={{
                                    border: "none",
                                }} onClick={() => {
                                    setIsControllerOpen(!isControllerOpen)
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Game+Pad.png" style={{
                                        width: "25px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Connection">
                                <Button variant='outlined' onClick={() => {
                                    handleConnectionTab(!connection.isConnectionTab)
                                }} style={{
                                    border: "none",
                                    width: "50px",
                                    marginRight: 30
                                }}>
                                    <SettingsInputAntennaIcon />
                                </Button>
                            </Tooltip>
                            <h6>Cable</h6>
                            <Switch defaultChecked={isWiFi} onChange={() => setIsWiFi(!isWiFi)} checked={isWiFi} />
                            <h6>Wi-Fi</h6>
                        </Typography>



                    </div>
                    <div>

                    </div>
                    <CodeEditor code={code}
                        setCode={setCode} />
                </div>

            </div>
            <ContentDialogBox
                    isOpen={isControllerOpen}
                    onClose={() => { setIsControllerOpen(false) }}
                    content={
                        <>
                            <ControllerComponent newBlocks={newBlocks} />
                        </>
                    }
                    title='Your Controller'
                    maxWidth="xs"
                />
        </motion.div>

    )
}