import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routes } from './routes'
import Home from '../pages/Home/index'
import Dashboard from '../pages/Dashboard'
import DashboardProject from '../pages/Projects'
import DashboardPage from '../pages/Dashboard'
import WorkspacePage from '../pages/Dashboard/Workspace'
import ModelsPage from '../pages/Dashboard/Models'
import ExtensionsPage from '../pages/Dashboard/Extentions'
import ExtensionPage from '../pages/Dashboard/Extentions/Extension'
import ModellingWorkSpacePage from '../pages/Dashboard/Models/ModellingWorkspace'
import MicroPythonEditorPage from '../pages/MicropythonEditor'
import WorkSpaceEditorPage from '../pages/Dashboard/Workspace/WorkSpaceEditor'
import FaceDetectionPage from '../Trials/FaceDetection'
import ObjectDetectionPage from '../Trials/ObjectDetection'
import PoseDetectionPage from '../Trials/PoseDetection'

function Router() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<Home />} />
            <Route path={routes.ROBOTICS_IDE} element={<MicroPythonEditorPage />} />





            <Route path={routes.TRIAL_EXTENSIONS.FACE_DETECTION} element={<FaceDetectionPage />} />
            <Route path={routes.TRIAL_EXTENSIONS.OBJECT_DETECTION} element={<ObjectDetectionPage />} />
            <Route path={routes.TRIAL_EXTENSIONS.POSE_DETECTION} element={<PoseDetectionPage />} />






            <Route path={routes.DASHBOARD.DASHBOARD} element={<Navigate to={routes.DASHBOARD.ROOT} replace={true} />} />
            <Route path={routes.DASHBOARD.ROOT} element={<DashboardPage />} />
            <Route path={routes.DASHBOARD.WORKSPACES} element={<WorkspacePage />} />
            <Route path={routes.DASHBOARD.MODELS} element={<ModelsPage />} />
            <Route path={routes.DASHBOARD.EXTENSIONS} element={<ExtensionsPage />} />
            <Route path={routes.DASHBOARD.EXTENSION} element={<ExtensionPage />} />
            <Route path={routes.DASHBOARD.MODELING_WORKSPACE} element={<ModellingWorkSpacePage />} />
            <Route path={routes.DASHBOARD.WORKSPACE_EDITOR} element={<WorkSpaceEditorPage />} />
            <Route path={routes.NOTFOUND} element={<div>Not Found</div>} />
            <Route path={routes.WILDCARD} element={<Navigate to={routes.NOTFOUND} replace={true} />} />
        </Routes>
    )
}

export default Router